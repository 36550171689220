import { AxiosResponse } from "axios";
import AxiosService from "./Axios";
import BaseApi from "./BaseApi";

class TreeBaseApi extends BaseApi {
    public async move(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/move', {
            data: data
        });
    }

    public async createGroup(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/delete', {
            data: data
        });
    }

    public async getChildren(groupId: any): Promise<AxiosResponse<any>> {
        return AxiosService.get(this.baseUrl + '/children', {
            data: {groupId: groupId}
        });
    }

    public async getTree(): Promise<AxiosResponse<any>> {
        return AxiosService.get(this.baseUrl + '/tree');
    }
};

export default TreeBaseApi;
