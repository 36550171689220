import styled from "styled-components";

export const CreateDeviceWizardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 650px;
    box-sizing: border-box;
`;


export const ProgressStatusWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const StepTitle = styled.div`
    font-size: 18px;
`;

export const ProgressContent = styled.div`
    flex: 1 1 auto;
    margin: 10px 0px;
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
`;

// for choosing node step
export const ChooseNodeStepWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export const NodeListWrapper = styled.div`
    flex: 0 0 auto;
    width: 300px;
    padding: 5px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
`;

export const NodeListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    &:hover {
        background-color: #EEEEEE;
    }
`;

export const NodeProductModel = styled.div`
    flex: 0 0 auto;
    width: 80px;
`;

export const NodeSn = styled.div`
    flex: 1 1 auto;
`;

export const NodeMapWrapper = styled.div`
    flex: 1 1 auto;
    padding: 0px 0px 0px 10px
`;

