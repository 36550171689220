
import { FolderOutlined, SearchOutlined, WifiOutlined } from "@ant-design/icons";
import { Input, Tree, TreeDataNode } from "antd";
import { useEffect, useRef, useState } from "react";
import { DeviceSelectionWrapper, SearchWrapper, TreeWrapper } from "./Deviceselection.styled";
import DeviceGroupApi from "../../../../api/DeviceGroupApi";
import StringUtils from "../../../../util/StringUtils";

interface DeviceSelectionProps {
    onSelect: (selectedDeviceId: string)=> void
}

const DeviceSelection: React.FC<DeviceSelectionProps> = (props)=> {
    // api
    let deviceGroupApi = new DeviceGroupApi();
    
    // state
    let [deviceTreeData, setDeviceTreeData] = useState(null);

    // events
    let onSelect= (selectedKeys: React.Key[], info: any) => {
      if(selectedKeys.length <=0) {
        return;
      }
      props.onSelect(selectedKeys[0]+'');
    };
    
    // onload
    useEffect(()=> {
      let loadDeviceTreeView=async ()=> {
        let deviceTreeView = (await deviceGroupApi.getDeviceTreeView()).data.data;

        let buildAntdTreeDataNode = (deviceNode: any)=> {
          // basic information
          let dataNode: any = {};
          let deviceNodeData = (deviceNode.deviceGroup || deviceNode.device);
          dataNode.title = deviceNodeData.name;
          dataNode.key = deviceNodeData.id;
          dataNode.icon = StringUtils.equalsIgnoreCase(deviceNode.type, 'GROUP') ? <FolderOutlined />: <WifiOutlined />;
          
          // child nodes
          if (deviceNode.children != null) {
            dataNode.children = [];
            for(let child of deviceNode.children) {
              let childDataNode = buildAntdTreeDataNode(child);
              dataNode.children.push(childDataNode);
            }
          }

          // return
          return dataNode;
        }

        // update state
        let rootNode = buildAntdTreeDataNode(deviceTreeView);
        let treeData = rootNode.children;
        setDeviceTreeData(treeData);
      }

      loadDeviceTreeView();
    }, []);


    return (
        <DeviceSelectionWrapper>
            <SearchWrapper>
                <Input placeholder="请输入设备名" suffix={<SearchOutlined />} />
            </SearchWrapper>

            <TreeWrapper>
                <Tree
                  showLine={true}
                  showIcon={true}
                  onSelect={onSelect}
                  treeData={deviceTreeData}
                />
            </TreeWrapper>
        </DeviceSelectionWrapper>
    )
}

export default DeviceSelection;
