class UrlUtils {
    public static getURLParams(url: string): Map<string, string> {
        let params: Map<string, string>=new Map();
        let keyValues=url.substring(1).split('&');
        for(let keyValue of keyValues) {
            let eqPos=keyValue.indexOf('=');
            let key=keyValue.substring(0, eqPos);
            let value=keyValue.substring(eqPos+1);

            params.set(decodeURIComponent(key), decodeURIComponent(value));
        }
        return params;
    }

}

export default UrlUtils;
