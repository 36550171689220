
import { Tabs } from "antd";
import DeviceApi from "../../../../api/DeviceApi";
import { DeviceDetailsWrapper, DeviceNameTitle, GoBackText, GoBackWrapper, TitleBar } from "./DeviceDetails.styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewStateService from "../../../../services/view-state/ViewStateService";
import DeviceInformation from "./components/device-information/DeviceInformation";
import DeviceSettings from "./components/device-settings/DeviceSettings";
import RealtimeDeviceMetrics from "./components/realtime-device-metrics/RealtimeDeviceMetrics";
import HistoryDeviceMetrics from "./components/history-device-metrics/HistoryDeviceMetrics";
import PztDataFile from "./components/pzt-data-file/PztDataFileManagement";

interface DeviceDetailsProps {
    onClose: ()=> void,
    onComplete: ()=> void
}

const DeviceDetails: React.FC<DeviceDetailsProps> = (props)=> {
    // api
    let navigate = useNavigate();
    let location = useLocation();
    let viewStateService = ViewStateService.getInstance();
    let deviceApi = new DeviceApi();

    // parameters
    let {deviceId} = useParams();

    // state
    let [deviceView, setDeviceView] = useState(null);

    // events
    let loadDevice = async ()=> {
        if(!deviceId) {
            return;
        }

        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);
    }

    let onGoBack = ()=> {
        if(location.key=='default') {
            navigate("/device-management");
        } else {
            navigate(-1);
        }
    }
    
    useEffect(()=> {
        // set status bar title
        viewStateService.setStatusBarTitle('设备详情');

        // load device information according to device id parameter
        loadDevice();

    }, []);
    
    // render
    let tabItems = [
        {
            key: 'device-information',
            label: '设备信息',
            children: deviceView && <DeviceInformation deviceView={deviceView} />
        },
        {
            key: 'device-settings',
            label: '阈值设置',
            children: deviceView && <DeviceSettings deviceView={deviceView} />
        },
        {
            key: 'pzt-data-file',
            label: '数据文件',
            children: deviceView && <PztDataFile deviceView={deviceView} />
        },
    ];

    return (
        <DeviceDetailsWrapper>
            <TitleBar>
                <GoBackWrapper onClick={onGoBack}>
                    <LeftCircleFilled style={{fontSize: '20px'}} />
                    <GoBackText>返回</GoBackText>
                </GoBackWrapper>
                <DeviceNameTitle>{deviceView?.device.name}</DeviceNameTitle>
            </TitleBar>
    
            { deviceView && 
                <Tabs
                    defaultActiveKey="device-information"
                    items={tabItems}
                    style={{flex: '1 1 auto', overflow: 'hidden'}}
                />
            }
        </DeviceDetailsWrapper>
    )
}

export default DeviceDetails;

