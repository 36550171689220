import styled from "styled-components";

export const BracketBorderCardWrapper = styled.div`
    position: relative;
    border: 1px solid rgb(95, 112, 150, 0.3);
    box-sizing: border-box;
`;

export const BracketBorderLeftTop = styled.div`
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 10px;
    border-left: 1px solid rgba(91, 112, 104, 0.85);
    border-top: 1px solid rgba(91, 112, 104, 0.85);
`;

export const BracketBorderLeftBottom = styled.div`
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 10px;
    height: 10px;
    border-left: 1px solid rgba(91, 112, 104, 0.85);
    border-bottom: 1px solid rgba(91, 112, 104, 0.85);
`;

export const BracketBorderRightTop = styled.div`
    position: absolute;
    top: -1px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-right: 1px solid rgba(91, 112, 104, 0.85);
    border-top: 1px solid rgba(91, 112, 104, 0.85);
`;

export const BracketBorderRightBottom = styled.div`
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-right: 1px solid rgba(91, 112, 104, 0.85);
    border-bottom: 1px solid rgba(91, 112, 104, 0.85);
`;


