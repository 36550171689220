import styled from "styled-components";

export const DeviceDetailsWrapper = styled.div`
    display: grid;
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    grid-gap: 10px 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, 72px);
    align-items: stretch;
    justify-items: stretch;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 72px;
    padding: 5px 12px;
    box-sizing: border-box;
    border: 1px solid rgb(95, 112, 150, 0.3);
`;

export const InfoLabel = styled.span`
    font-size: 14px;
    opacity: 0.5;
`;

export const InfoValue = styled.span`
    font-size: 16px;
    margin-top: 6px;
    height: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
`;
