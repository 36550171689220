import styled from "styled-components";


export const DeviceTemplateDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;

    & .ant-tabs-tab {
        padding-left: 10px;
        padding-right: 10px;
    }

    & .ant-tabs-content {
        height: 100%;
    }

    & .ant-tabs-tabpane {
        height: 100%;
    }
`;

export const TitleBar = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 20px 0px;
`;

export const GoBackWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const GoBackText = styled.span`
    margin-left: 10px;
`

export const DeviceTemplateNameTitle = styled.div`
    margin-left: 20px;
    font-size: 18px;
`
