import styled from "styled-components";

export const DeviceSelectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const SearchWrapper = styled.div`
    flex: 0 0 auto;
`

export const TreeWrapper = styled.div`
    flex: 1 1 auto;
    margin: 10px 0px;
`;

