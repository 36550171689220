import styled from "styled-components";

export const FirmwareManagementWrapper = styled.div`

`;

export const ToolBarWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 20px;
`;

export const ToolBarLeftWrapper = styled.div`
    flex: 0 0 auto;
`

export const ToolBarRightWrapper = styled.div`
    flex: 0 0 auto;
`

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
`;

