interface ProductManagementProps {

}

const ProductManagement: React.FC<ProductManagementProps> = (props)=> {
    // render
    return (
        <div>Product Management, Developing...</div>
    );
}

export default ProductManagement;
