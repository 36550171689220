import { Button, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { CreateDeviceTemplateWizardWrapper, FormWrapper, OperationWrapper, Title, TitleWrapper } from "./CreateDeviceTemplateWizard.styled";
import ProductApi from "../../../../api/ProductApi";
import DeviceTemplateApi from "../../../../api/DeviceTemplateApi";

interface CreateDeviceTemplateWizardProps {
    onClose: ()=> void,
    onComplete: ()=> void
}

export const CreateDeviceTemplateWizard: React.FC<CreateDeviceTemplateWizardProps> = (props)=> {
    // api
    let deviceTemplateApi = new DeviceTemplateApi();
    let productApi = new ProductApi();
    const [form] = Form.useForm();

    // state
    let [submitEnabled, setSubmitEnabled] = useState(false);
    let [createDeviceTemplateParams, setCreateDeviceParameters] = useState({} as any);
    let [nodeTypeOptions, setNodeTypeOptions] = useState([]);
    let [productModelOptions, setProductModelOptions] = useState([]);

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    }

    // events
    let onDeviceTemplateNameChange = (e)=> {
        createDeviceTemplateParams.name = e.target.value;
        validateForm();
    };

    let onNodeTypeChange= (selectedValue: string)=> {
        createDeviceTemplateParams.nodeType = selectedValue;
        validateForm();
    };

    let onProductModelChange= (selectedValue: string)=> {
        createDeviceTemplateParams.productModel = selectedValue;
        validateForm();
    };

    let onRemarksChange = (e)=> {
        createDeviceTemplateParams.remarks = e.target.value;
        validateForm();
    };

    let onSubmit = async (e)=> {
        await deviceTemplateApi.create(createDeviceTemplateParams);
        props.onComplete();
        props.onClose();
    };

    let onCancel = (e)=> {
        props.onClose();
    };

    let initializeData = async ()=> {
        // node options
        let nodeTypeOptions = [
            {value: 'DIRECT', label: '直连设备'},
            {value: 'GATEWAY', label: '网关设备'},
            {value: 'SLAVE', label: '子设备'},
        ];
        setNodeTypeOptions(nodeTypeOptions);
    
        // product model
        let products = (await productApi.getAll()).data.data;
        let productModelOptions = products.map((product)=> {
            let productOption = {
                label: `${product.model} (${product.name})`,
                value: product.model
            };
            return productOption;
        });
        setProductModelOptions(productModelOptions);
    }

    // use effect
    useEffect(()=> {
        initializeData();
    }, []);
    
    // render
    return (
        <CreateDeviceTemplateWizardWrapper>
            <TitleWrapper>
                <Title>创建设备模板</Title>
            </TitleWrapper>

            <FormWrapper>
                <Form form={form} name="form" layout="vertical">
                    <Form.Item name="name" label="模板名称" rules={[{required: true}]}>
                        <Input onChange={onDeviceTemplateNameChange} />
                    </Form.Item>

                    <Form.Item name="nodeType" label="节点类型" rules={[{required: true}]} initialValue={createDeviceTemplateParams.nodeType}>
                        <Select
                            placeholder="请选择节点类型"
                            defaultValue={createDeviceTemplateParams.nodeType}
                            value={createDeviceTemplateParams.nodeType}
                            onChange={onNodeTypeChange}
                            options={nodeTypeOptions}
                        />
                    </Form.Item>

                    <Form.Item name="productModel" label="产品型号" rules={[{required: true}]}>
                        <Select
                            placeholder="请选择产品型号"
                            defaultValue={createDeviceTemplateParams.productModel}
                            value={createDeviceTemplateParams.productModel}
                            onChange={onProductModelChange}
                            options={productModelOptions}
                        />
                    </Form.Item>

                    <Form.Item name="remarks" label="备注" rules={[{required: false}]}>
                        <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange}/>
                    </Form.Item>
                </Form>
            </FormWrapper>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!submitEnabled} onClick={onSubmit}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </CreateDeviceTemplateWizardWrapper>
    )
}
