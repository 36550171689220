
class EventManager {

    private deviceSelectedListeners: DeviceSelectedListener[] = [];

    public addDeviceSelectedListener(listener: DeviceSelectedListener): void {
        if (this.deviceSelectedListeners.indexOf(listener)>=0) {
            return;
        }
        this.deviceSelectedListeners.push(listener);
    }

    public removeDeviceSelectedListener(listener: DeviceSelectedListener): void {
        let index = this.deviceSelectedListeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.deviceSelectedListeners.splice(index, 1);
    }

    public notifyDeviceSelected(selectedDeviceId: string): void {
        for(let listener of this.deviceSelectedListeners) {
            listener(selectedDeviceId);
        }
    }
    

}

interface DeviceSelectedListener {
    (deviceId: string): void
}

export default EventManager;
