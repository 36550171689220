
class DeviceTemplateUtils {

    private static nodeTypeMetas = [
        {ordinal: 0, name: 'DIRECT', text: '直连设备'},
        {ordinal: 1, name: 'GATEWAY', text: '网关设备'},
        {ordinal: 2, name: 'SLAVE', text: '网关子设备'},
    ];

    private static productResourceTypeMetas = [
        {ordinal: 0, name: 'ADC_CHANNEL', text: 'ADC'},
        {ordinal: 1, name: 'UART', text: '串口'},
    ];
    
    public static nodeType2Text(nodeType: string) {
        for(let nodeTypeMeta of this.nodeTypeMetas) {
            if(nodeTypeMeta.name == nodeType) {
                return nodeTypeMeta.text;
            }
        }
        return nodeType;
    }

    public static productResourceType2Text(productResourceType: string) {
        for(let productResourceTypeMeta of this.productResourceTypeMetas) {
            if(productResourceTypeMeta.name == productResourceType) {
                return productResourceTypeMeta.text;
            }
        }
        
        return productResourceType;
    }
}

export default DeviceTemplateUtils;
