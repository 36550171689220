import SessionService from "../../../../services/session/SessionService";
import { Clock } from "../../overview/components/clock/Clock";
import { Weather } from "../../overview/components/weather/Weather";
import { BorderLeftBlock, BorderLeftCircle, BorderLine, BorderRightLine, BorderRightCircle, NavigateItem, NavigateItems, NavigatorCenter, NavigatorLeft, NavigatorRight, NavigatorWrapper, ScreenTitle, ScreenTitleCn, ScreenTitleEn, TitleBorder, BorderRightBlock, NavigateItemSeparator } from "./Navigator.styled";

import WinchIllustration from '../../../../assets/images/winch-illustration.png';

interface NavigatorProps {
    selected?: string
}

let Navigator: React.FC<NavigatorProps> = (props)=> {
    return (
        <NavigatorWrapper>
            <NavigatorLeft>
                <img src={WinchIllustration} style={{width: '45px', height: '45px', margin: '0px 20px'}} />
                <ScreenTitle>
                    <ScreenTitleCn>智能卷扬机互联系统</ScreenTitleCn>
                    <ScreenTitleEn>Winch Intelligent Link System</ScreenTitleEn>
                </ScreenTitle>
            </NavigatorLeft>

            <NavigatorCenter>
                <NavigateItems>
                    <NavigateItem href={SessionService.getInstance().getCurrentUserProfile().admin ? "/#/" : '#'}>管理控制台</NavigateItem>
                    <NavigateItemSeparator />
                    <NavigateItem href="/#/big-screen/overview" className={props.selected=='OVERVIEW' ? "selected" : "" }>系统总览</NavigateItem>
                    <NavigateItemSeparator />
                    <NavigateItem className={props.selected=='DEVICE_DETAILS' ? "selected" : "" }>设备详情</NavigateItem>
                </NavigateItems>

                <TitleBorder>
                    <BorderLeftCircle />
                    <BorderLeftBlock />
                    <BorderLine />
                    <BorderRightLine />
                    <BorderRightBlock />
                    <BorderRightCircle />
                </TitleBorder>

            </NavigatorCenter>

            <NavigatorRight>
                <Weather />
                <Clock />
            </NavigatorRight>
        </NavigatorWrapper>
    )
};

export default Navigator;
