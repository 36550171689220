import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class LoginApi {
    
    public async signIn(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post('/login', data);
    }

    public async signOut(): Promise<AxiosResponse<any>> {
        return Promise.resolve(null);
    }
}

export default LoginApi;
