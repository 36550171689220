
import styled from "styled-components";

export const DeviceAlarmsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 10px;
`;


export const TodayAlarmsOverview = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const TodayTotal = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TodayUnprocessed = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TodayProcessed = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverviewItemLabel = styled.div`
    padding: 5px 0px;
`;

export const OverviewItemValue = styled.div`
    font-size: 24px;
`;

export const HistoricalAlarmsChart = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const EChartWrapper = styled.div`
    width: 100%;
    height: 150px;
`;

