import styled from "styled-components";

export const DeviceStatusWrapper = styled.div`
    display: inline-block;
    padding: 3px 0px;
`;

export const StatusText = styled.div`
    margin: 0px 5px 0px 0px;
`;

export const OnlineStatus = styled.div`
    display: flex;
    align-items: center;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const OfflineStatus = styled.div`
    display: flex;
    align-items: center;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
