
class ScaleUtils {

    private static globalScale: number = 1.0;

    public static setGlobalScale(globalScale: number): void {
        this.globalScale = globalScale;
    }

    public static getGlobalScale(): number {
        return this.globalScale;
    }
}

export default ScaleUtils;
