import styled from "styled-components";


export const DevicesMapWrapper = styled.div`
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    
    .amap-info-content {
        background-color: rgba(0, 0, 0, 0.5);
        padding-right: 10px;
        z-index: 200;
    }

    .amap-info-sharp {
        border-top: 8px solid rgba(0, 0, 0, 0.2);
    }
`;

export const DevicePopupWrapper = styled.div`
    width: 250px;
`;

export const DevicePopupName = styled.div`
    
`;

export const DevicePopupSn = styled.div`
    
`;

export const DevicePopupMetricsWrapper = styled.div`

`;

export const DevicePopupMetricWrapper = styled.div`

`;

export const DevicePopupMetricName = styled.div`
    display: inline-block;
`;

export const DevicePopupMetricValue = styled.div`
    display: inline-block;
`;
