import styled from "styled-components";

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
`;

export const OverviewChartsWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const OverviewChartWrapper = styled.div`
    flex: 1 1 auto;
    padding: 20px 20px;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #DDDDDD;
    
    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
`;

export const OverviewChartTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 0px 0px 10px 0px;
`;

export const OverviewChartTitleText = styled.div`
    text-align: center;
    font-size: 16px;
`;

export const OverviewContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const OverviewEChartWrapper = styled.div`
    width: 125px;
    height: 125px;
`;


export const OverviewLegend = styled.div`
    flex: 0 0 auto;
    dispaly: flex;
    flex-direction: column;
    margin-left: 20px;
`;

export const OverviewLegendItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
`;

export const LegendColor = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
`;

export const LegendTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    width: 80px;
    margin-left: 10px;
`;

export const LegendValue = styled.div`
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
`;

export const DevicesMapWrapper = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 10px 0px;
`;

export const DeviceSelectionWrapper = styled.div`
    position: absolute;
    display: inline-block;
    left: 5%;
    top: 10%;
    width: 250px;
    height: 450px;
    max-height: 80%;
    padding: 10px;
    background-color: white;
`;

export const DeviceEventInterceptWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const DevicePopupWrapper = styled.div`
    width: 250px;
`;

export const DevicePopupName = styled.div`
    
`;

export const DevicePopupSn = styled.div`
    
`;

export const DevicePopupMetricsWrapper = styled.div`

`;

export const DevicePopupMetricWrapper = styled.div`

`;

export const DevicePopupMetricName = styled.div`
    display: inline-block;
`;

export const DevicePopupMetricValue = styled.div`
    display: inline-block;
`;




