import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GUI } from 'dat.gui';
import { DeviceModel3DWrapper } from "./DeviceModel3D.styled";
import { memo, useEffect, useRef } from 'react';

interface DeviceModel3DProps {

}

let DeviceModel3D: React.FC<DeviceModel3DProps> = memo((props)=> {
    let threeContextRef = useRef(null as {
        scene: any,
        renderer: any,
        camera: any,
    });

    let destroyScene = ()=> {
        if (threeContextRef.current) {
            let threeContext = threeContextRef.current;
            threeContextRef.current = null;
            threeContext.renderer.domElement.addEventListener('dblclick', null, false);
        }
    }

    let initializeScene = (dom: HTMLElement)=> {
        if (dom == null) {
            destroyScene();
            return;
        }

        let loader = new GLTFLoader();
        loader.load('/static/3d-model/winch-model-1.0.0.glb', (gltf)=> {
            gltf.scene.scale.set(180, 180, 180);
            gltf.scene.position.y -= 40;
            let material = new THREE.MeshBasicMaterial({ 
                wireframe: true ,
                transparent: true,
                opacity: 0.2,
                color: '#E8870D',
            });
            /*
            gltf.scene.traverse((child)=> {
                child.material = material;
            });
            */

            // scene
            let scene = new THREE.Scene();
            scene.add(gltf.scene);

            // light
            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(100, 100, 100);
            scene.add(directionalLight);

            // camera
            let camera = new THREE.PerspectiveCamera(75, dom.clientWidth / dom.clientHeight, 0.1, 1000);
            camera.position.x = 130;
            camera.position.y = 0;
            camera.position.z = 130;
            camera.lookAt(0, 0, 0);

            // renderer
            let renderer = new THREE.WebGLRenderer();
            renderer.setSize(dom.clientWidth, dom.clientHeight);
            dom.appendChild(renderer.domElement);

            // set context
            threeContextRef.current = {
                scene: scene,
                camera: camera,
                renderer: renderer,
            };

            // animation
            let prevTime = new Date().getTime();
            let animate = ()=> {
                if (threeContextRef.current==null) {
                    return;
                }
                requestAnimationFrame(animate);
                let deltaTime = new Date().getTime() - prevTime;
                prevTime = new Date().getTime();
                let rotateDelta = deltaTime / 15000 * 2 * Math.PI;

                gltf.scene.rotation.y += rotateDelta;
                renderer.render(scene, camera);
            }
            animate();
        }, undefined, (error)=> {
            console.log(error);
        });
    };

    useEffect(()=> {
        return ()=> {
            destroyScene();
        }
    });

    // render
    return <DeviceModel3DWrapper ref={(dom)=> initializeScene(dom)}>
    </DeviceModel3DWrapper>
});

export default DeviceModel3D;