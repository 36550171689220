import { useEffect, useRef, useState } from "react";
import DeviceApi from "../../../../../api/DeviceApi";
import { Content, DeviceName, DeviceNetworkTrafficWrapper, DeviceTrafficStatisticsItem, Title, TrafficFree, TrafficUsageBar, TrafficUsageText, TrafficUsed } from "./DeviceNetworkTraffix.styled";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";

interface DeviceNetworkTrafficProps {

}

let DeviceNetworkTraffic: React.FC<DeviceNetworkTrafficProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // ref
    let carouselRef = useRef<any>();

    // state
    let [deviceViews, setDeviceViews] = useState([]);

    // event
    let initializeCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };

    // onload
    useEffect(()=> {
        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            deviceViews = deviceViews.map((deviceView)=> {
                deviceView.node.trafficTotal = deviceView.latestSimCardTraffic?.total ?? 100;
                deviceView.node.trafficUsed = deviceView.latestSimCardTraffic?.used ?? 0.00;
                if (deviceViews.indexOf(deviceView)==0) {
                    deviceView.node.trafficUsed = 0.00;
                }
                return deviceView;
            }).sort((a, b)=> {
                let aTrafficFree = a.node.trafficTotal - a.node.trafficUsed;
                let bTrafficFree = b.node.trafficTotal - b.node.trafficUsed;
                return aTrafficFree - bTrafficFree;
            });
            setDeviceViews(deviceViews);
        }
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
        }, 120000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    return (
        <DeviceNetworkTrafficWrapper>
            <Title>设备流量统计</Title>
            <Content ref={(dom)=> initializeCarousel(dom)}>
                {
                    deviceViews.map((e)=> {
                        return <DeviceTrafficStatisticsItem key={e.device.id}>
                            <DeviceName>{e.device.name}</DeviceName>
                            <TrafficUsageBar>
                                <TrafficUsed style={{width: (e.node.trafficUsed / e.node.trafficTotal * 100.0) + '%'}}/>
                                <TrafficFree style={{width: ((e.node.trafficTotal - e.node.trafficUsed) / e.node.trafficTotal * 100.0) + '%'}}/>
                            </TrafficUsageBar>
                            <TrafficUsageText>
                                {e.node.trafficUsed.toFixed(2)} / {e.node.trafficTotal} MB
                            </TrafficUsageText>
                        </DeviceTrafficStatisticsItem>
                    })
                }
            </Content>
        </DeviceNetworkTrafficWrapper>
    )
};

export default DeviceNetworkTraffic;

