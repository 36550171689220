import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/firmware";

class FirmwareApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getAllFirmwareViews(): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/firmware-view/all');
    }
}

export default FirmwareApi;
