import styled from "styled-components";

export const CreateUserWizardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 700px;
    box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const FormWrapper = styled.div`
    flex: 1 1 auto;
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px;
`;
