import { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { BracketBorderCardWrapper, BracketBorderLeftBottom, BracketBorderLeftTop, BracketBorderRightBottom, BracketBorderRightTop } from "./BracketBorderCard.styled";

interface BracketBorderCardProps {
    style?: CSSProperties,
    children?: ReactNode[] | ReactNode,
}

let BracketBorderCard: React.FC<BracketBorderCardProps> = (props)=> {

    return <BracketBorderCardWrapper style={props.style}>
        <BracketBorderLeftTop />
        <BracketBorderLeftBottom />
        <BracketBorderRightTop />
        <BracketBorderRightBottom />

        {props.children}
    </BracketBorderCardWrapper>
};

export default BracketBorderCard;
