import { useEffect, useState } from "react";
import { FilterBar, FilterItem, FilterItemLabel, PztDataFileManagementWrapper, TableWrapper } from "./PztDataFileManagement.styled";
import { DatePicker, Select, Space, Table, TableColumnsType, message } from "antd";
import StringUtils from "../../../../../../util/StringUtils";
import Api from "../../../../../../api/Api";
import moment from "moment";
import SessionService from "../../../../../../services/session/SessionService";
import PztDataFileApi from "../../../../../../api/PztDataFileApi";
import dayjs from "dayjs";

interface PztDataFileProps {
    deviceView: any
}

let PztDataFile: React.FC<PztDataFileProps> = (props)=> {
    // params
    let deviceView = props.deviceView;

    // api
    let pztDataFileApi = new PztDataFileApi();
    let [messageApi, contextHolder] = message.useMessage();

    // state
    let [loading, setLoading] = useState(true);
    let [dataType, setDataType] = useState('EXCEPTION');
    let [dataFileViews, setDataFileView] = useState([]);

    let [startTime, setStartTime] = useState(dayjs().add(-1, 'd'));
    let [endTime, setEndTime] = useState(dayjs());

    // signal types
    let dataTypeOptions = [
        { value: 'EXCEPTION', label: '异常数据' },
        { value: 'NORMAL', label: '正常' },
    ];

    // functions
    let loadPztDataFileViews = async ()=> {
        setLoading(true);
        let pztDataFileViews = (await pztDataFileApi.getDevicePztDataFileViews(deviceView.device.id)).data.data;
        for(let pztDataFileView of pztDataFileViews) {
            pztDataFileView.children=pztDataFileView.datePztDataFileViews;
        }
        setDataFileView(pztDataFileViews);
        setLoading(false);
    };

    let formatDataType = (dataType)=> {
        for(let dataTypeOption of dataTypeOptions) {
            if (dataTypeOption.value == dataType) {
                return dataTypeOption.label;
            }
        }
        return dataType;
    };

    let getDownloadDataFileUrl = (dataFileView)=> {
        // let downloadUrl = Api.URL_PREFIX + `/pzt/data-file/${dataFileView.dataFile.dataType.toLowerCase()}/${moment(parseInt(dataFileView.detaFile.created)).format('YYYY-MM-DD')}/${dataFileView.name}?&authorization=${SessionService.getInstance().getAuthoriazation()}`;
        let downloadUrl = `/pzt-data-file/${dataFileView.pztDataFile.path}`;
        return downloadUrl;
    };

    let onDateTimeRangeChange = (dateTimeRangeValue: any[])=> {
        setStartTime(dateTimeRangeValue[0]);
        setEndTime(dateTimeRangeValue[1]);
    };

    // effect
    useEffect(()=> {
        loadPztDataFileViews();
    }, []);

    // render
    const dataFileColumns: TableColumnsType<any> = [
        {
            title: '日期/文件名',
            dataIndex: 'name',
            key: 'name',
            width: '400px',
            render: (text, record, index)=> {
                return <span>{record.pztDataFile.name}</span>
            },
        },
        {
            title: '数据类型',
            dataIndex: 'dataType',
            key: 'dataType',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{formatDataType(record.pztDataFile.type)}</span>
            }
        },
        {
            title: '文件大小',
            dataIndex: 'size',
            key: 'size',
            align: 'center',
            render: (text, record, index)=> {
                if (record.pztDataFile.size == null) {
                    return StringUtils.emptyToSlash('');
                }
                let size = record.pztDataFile.size;
                if (size < 1024 * 1024) {
                    return (size / 1024).toFixed(2) + ' KB'
                } else if (size < 1024 * 1024 * 1024) {
                    return (size / 1024 / 1024).toFixed(2) + 'MB';
                } else {
                    return (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
                }
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return record.pztDataFile.id && <Space size="middle">
                    <a href={getDownloadDataFileUrl(record)}>下载数据文件</a>
                </Space>
            }
        }
    ];

    return <PztDataFileManagementWrapper>
            {contextHolder}
            <FilterBar>
                <Space size="middle">
                    <FilterItem>
                        <FilterItemLabel>信号类型</FilterItemLabel>
                        <Select
                            maxTagCount={2}
                            style={{ width: '150px' }}
                            placeholder="请选择数据类型"
                            value={dataType}
                            onChange={(selectedValue)=> setDataType(selectedValue)}
                            options={dataTypeOptions}
                        />
                    </FilterItem>
                    
                    <FilterItem>
                        <FilterItemLabel>时间范围</FilterItemLabel>
                        <DatePicker.RangePicker
                            style={{width: '330px'}}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            defaultValue={[dayjs().add(-1, 'd'), dayjs()]}
                            value={[startTime, endTime]}
                            allowEmpty={[false, true]}
                            onOk={onDateTimeRangeChange}
                        />
                    </FilterItem>
                </Space>
            </FilterBar>
            <TableWrapper>
                <Table 
                    rowKey={(record)=> record.productModel + record.version}
                    columns={dataFileColumns} 
                    dataSource={dataFileViews}
                />
            </TableWrapper>

    </PztDataFileManagementWrapper>
};

export default PztDataFile;
