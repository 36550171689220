import styled from "styled-components";

export const AccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 240px;
`;

export const Information = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin: 18px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #101010;
    box-shadow: 0 0 3px #000000;
    color: #FEFEFE;
    font-size: 60px;
    text-align: center;
    cursor: pointer;
`;

export const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 12px 0 12px;
    & .anticon {
        padding-right: 12px;
        font-size: 18px;
        color: #555555;
    }
`;

export const Name = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0px;
    font-size: 12px;
`;

export const PhoneNumber = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0px;
    font-size: 12px;
`;

export const Email = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0px;
    font-size: 12px;
`;

export const Operations = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-top: 12px;
    margin-top: 12px;
    box-sizing: border-box;
    cursor: pointer;
    border-top: 1px solid #CCCCCC;
`;

export const Operation = styled.div`
    display: flex;
    height: 36px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    user-select: none;
    padding: 0px 12px;
    border-radius: 18px;
    box-sizing: border-box;
    font-size: 12px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
    }
`;

