interface DeviceGroupManagementProps {

}

const DeviceGroupManagement: React.FC<DeviceGroupManagementProps> = (props)=> {
    // render
    return (
        <div>Device Group Management, Developing...</div>
    );
}

export default DeviceGroupManagement;
