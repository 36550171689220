import styled from "styled-components";
import BigScreenBackgroundImage from '../assets/images/big-screen-background-3.png';

let titleBarHeight = 100;

export const DeviceDetailsScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #FFFFFF;
    letter-spacing: 2px;
    background-size: cover;
`;

export const DeviceDetailsScreenInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 850px;
    color: #FFFFFF;
    letter-spacing: 2px;
    background-color: rgba(0, 0, 0);
`;

export const ScreenTitleBar = styled.div`
    position: absolute;
    z-index: 100;
    left: 0%;
    top: 0%;
    width: 100%;
    height: ${titleBarHeight}px;
    padding: 30px 10px 10px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

export const DeviceDetailsContent = styled.div`
    display: grid;
    position: absolute;
    top: calc(${titleBarHeight}px);
    width: 100%;
    height: calc(100% - ${titleBarHeight}px);
    padding: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    grid-template-columns: 2fr 3fr 3fr;
    grid-template-rows: 3fr 3fr;
    grid-gap: 20px;
    align-items: stretch;
    justify-items: stretch;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const DetailsDetailsAndMapWrapper = styled.div`
    z-index: 100;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const DeviceDetailsWrapper = styled.div`
    width: 100%;
    flex: 0 0 auto;
    box-sizing: border-box;
`;

export const DeviceTrackWrapper = styled.div`
    width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;
`;

export const DeviceModel3DAndHealthWrapper = styled.div`
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    align-items: stretch;
`;

export const DeviceModel3DWrapper = styled.div`
    z-index: 100;
    overflow: hidden;
`;

export const DeviceHealthWrapper = styled.div`
    z-index: 100;
    box-sizing: border-box;
`;

export const DeviceRealtimeMetricsWrapper = styled.div`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    z-index: 100;
    box-sizing: border-box;
`;

export const DeviceMetricsWrapper = styled.div`
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    z-index: 100;
    box-sizing: border-box;
`;
