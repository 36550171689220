import React, { useEffect, useRef } from "react";
import { DeivceOnlineDurationWrapper, DeviceAlarmStatisticsWrapper, DeviceAlarmsWrapper, DeviceListWrapper, DeviceMapWrapper, DeviceOnlineStatisticsWrapper, DeviceTrafficStatisticsWrapper, OverviewBigScreenInnerWrapper, OverviewBigScreenWrapper, ScreenTitleBar } from "./OverviewBigScreen.styled";
import { DeviceMap } from "./components/device-map/DeviceMap";
import { DeviceOnlineStatistics } from "./components/device-online-statistics/DeviceOnlineStatistics";
import { DeviceList } from "./components/device-list/DeviceList";
import { DeviceAlarms } from "./components/device-alarm/DeviceAlarms";
import { DeviceAlarmStatistics } from "./components/device-alarm-statistics/DeviceAlarmStatistics";
import EventManager from "./common/EventManager";
import DeviceNetworkTraffic from "./components/device-network-traffic/DeviceNetworkTraffix";
import Navigator from "../common/navigator/Navigator";
import DeviceOnlineDuration from "./components/device-online-duration/DeviceOnlineDuration";

interface OverviewBigScreenProps {
    
}

export const OverviewBigScreen: React.FC<OverviewBigScreenProps> = (props)=> {
    // event manager
    let eventManagerRef = useRef(new EventManager());

    // effect
    useEffect(()=> {
        let originalBackground = document.body.style.backgroundColor;
        document.body.style.backgroundColor = '#2A2A2A';
        return ()=> {
            document.body.style.backgroundColor = originalBackground;
        };
    }, []);

    // render
    return (
        <OverviewBigScreenWrapper>
            <OverviewBigScreenInnerWrapper>
                <ScreenTitleBar>
                    <Navigator selected="OVERVIEW"/>
                </ScreenTitleBar>

                <DeviceMapWrapper>
                    <DeviceMap eventManager={eventManagerRef.current}/>
                </DeviceMapWrapper>
                
                <DeviceOnlineStatisticsWrapper>
                    <DeviceOnlineStatistics />
                </DeviceOnlineStatisticsWrapper>

                <DeviceListWrapper>
                    <DeviceList eventManager={eventManagerRef.current}/>
                </DeviceListWrapper>

                <DeviceTrafficStatisticsWrapper>
                    <DeviceNetworkTraffic />
                </DeviceTrafficStatisticsWrapper>

                <DeviceAlarmStatisticsWrapper>
                    <DeviceAlarmStatistics />
                </DeviceAlarmStatisticsWrapper>

                <DeviceAlarmsWrapper>
                    <DeviceAlarms />
                </DeviceAlarmsWrapper>

                <DeivceOnlineDurationWrapper>
                    <DeviceOnlineDuration />
                </DeivceOnlineDurationWrapper>
            </OverviewBigScreenInnerWrapper>

        </OverviewBigScreenWrapper>
    )
};
