import styled from "styled-components";

export const PztDataFileManagementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
`;

export const FilterBar = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;

    & .query-button .ant-btn-disabled {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button .ant-btn-disabled:hover {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button {
        background-color: #1CB273;
    }

    & .query-button:hover {
        background-color: #1EA77B !important;
    }
`;

export const FilterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-select-selector {
        overflow: hidden;
    }

    & .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
`;

export const FilterItemLabel = styled.div`
    font-size: 12px;
    color: #333333;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    letter-spacing: 2px;
`;

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
`;
