import axios from "axios";
import SessionService from "../services/session/SessionService";
import Api from "./Api";

const CODE_OK = "0";

const AxiosService = axios.create({
    timeout: 30000,
});

AxiosService.interceptors.request.use(
    (config) => {
        let sessionService = SessionService.getInstance();
        config.headers.Authorization = sessionService.getAuthoriazation();
        if (config.baseURL == null) {
            config.baseURL = Api.URL_PREFIX;
        }
        return config;
    },
    (error) => {
        console.log(error);
    }
)

AxiosService.interceptors.response.use((response) => {
    let code = response.data.code;
    let data = response.data.data;
    return response;
});


export default AxiosService;