import styled from "styled-components";

export const DeviceRssi = styled.div`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
`;

export const DeviceRssiWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
`;

export const DeviceRssiText = styled.div`
    display: inline-block;
`;

export const DeviceRssiIcon = styled.div`
    display: inline-flex;
    align-items: flex-end;
    height: 10px;
    margin: 0px 0px 0px 6px;
`;
