import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    
    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const BigScreenLayoutWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 1920px;
    height: 1080px;
`;

export const MainLayoutWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
`;


export const MainContentLayoutWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 1400px;
`;

export const SideBarWrapper = styled.div`
    flex: 0 0 auto;
    width: 240px;
    border-right: 1px solid #EEEEEE;
`;

export const ContentWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const StatusBarWrapper = styled.div`
    flex: 0 0 auto;
    top: 0px;
    right: 0px;
    height: 60px;
`;

export const RouterWrapper = styled.div`
    flex: 1 1 auto;
    padding: 0px 20px;
`;