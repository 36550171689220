import UserProfile from "./UserProfile";


class SessionService {

    private sessionType: SessionType;
    private authorization: string;
    private currentUserProfile: UserProfile;

    // listeners
    private sessionChangeListeners: Set<SessionChangeListener> = new Set();

    public getSessionType(): SessionType {
        return this.sessionType;
    }

    public setSessionType(sessionType: SessionType): void {
        this.sessionType = sessionType;
    }

    public getAuthoriazation(): string {
        if (this.sessionType == SessionType.GUEST) {
            return this.authorization;
        } else {
            return localStorage.getItem('authorization');
        }
    }

    public setAuthorization(authorization: string): void {
        if (this.sessionType == SessionType.GUEST) {
            this.authorization = authorization;
        } else {
            localStorage.setItem('authorization', authorization);
        }
    }

    public getCurrentUserProfile(): UserProfile {
        return this.currentUserProfile;
    }

    public setCurrentUserProfile(userProfile: UserProfile) {
        this.currentUserProfile = userProfile;
    }

    public addSessionChangeListener(sessionChangeListener: SessionChangeListener): void {
        this.sessionChangeListeners.add(sessionChangeListener);
    }

    public removeSessionChangeListener(sessionChangeListener: SessionChangeListener): void {
        this.sessionChangeListeners.delete(sessionChangeListener);
    }

    public fireSessionChangeListeners(): void {
        for(let sessionChangeListener of this.sessionChangeListeners) {
            sessionChangeListener();
        }
    }

    // singleton implements

    private static sessionService = new SessionService();

    public static getInstance(): SessionService {
        return this.sessionService;
    }
}

enum SessionType {
    NORMAL = 'NORMAL',
    GUEST = 'GUEST',
}

interface SessionChangeListener {
    (): void
}

export default SessionService;
export {SessionService, SessionType};
