import styled from "styled-components";


export const DeviceOnlineOfflineHistoryManagementWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ToolBarWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 20px;
`;

export const ToolBarLeftWrapper = styled.div`
    flex: 0 0 auto;
`

export const ToolBarRightWrapper = styled.div`
    flex: 0 0 auto;
`

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
`;

export const FilterBar = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;

    & .query-button .ant-btn-disabled {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button .ant-btn-disabled:hover {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button {
        background-color: #1CB273;
    }

    & .query-button:hover {
        background-color: #1EA77B !important;
    }
`;

export const FilterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-select-selector {
        overflow: hidden;
    }

    & .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
`;

export const FilterItemLabel = styled.div`
    font-size: 12px;
    color: #333333;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    letter-spacing: 2px;
`;

export const OnlineOfflineEventDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

export const InfoItemRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 250px;
    padding-right: 10px;
    box-sizing: border-box;
`;

export const InfoLabel = styled.span`
    font-size: 12px;
    color: #555555;
`;

export const InfoValue = styled.span`
    font-size: 14px;
    margin-top: 4px;
    color: #101010;
    white-space: nowrap;
    text-overflow: ellipse;
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px;
`;

export const OnlineEventType = styled.div`
    display: inline-block;
    border-radius: 5px;
    padding: 3px 10px;
    background-color: #1EA77B;
    color: #FFFFFF;
`;

export const OfflineEventType = styled.div`
    display: inline-block;
    border-radius: 5px;
    padding: 3px 10px;
    background-color: #FF4D4F;
    color: #FFFFFF;
`;
