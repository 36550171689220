import { ECharts } from "echarts";
import * as echarts from "echarts";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import SeriesColorUtils from "../../../../../util/SeriesColorUtils";
import DeviceApi from "../../../../../api/DeviceApi";
import { ChartWrapper, DeviceHealthWrapper, EChartsWrapper, Title } from "./DeviceHealth.styled";

interface DeviceHealthProps {
    deviceId: string
}

const DeviceHealth: React.FC<DeviceHealthProps> = (props)=> {
    let { deviceId } = props;

    // api
    let deviceApi = new DeviceApi();

    // ref
    let echartDomRef = useRef<HTMLElement>(null);
    let echartRef =useRef<ECharts>(null);

    // state
    let [deviceView, setDeviceView] = useState(null);

    // functions
    let loadDeviceView = async ()=> {
        // fetch device view data
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);

        // set charts data
        let timeNowMillis = new Date().getTime();
        let totalOnlineTimeInHours = deviceView.node.totalOnlineTime / 3600 / 1000;
        // let value = 0.15;
        let value = totalOnlineTimeInHours / 6000;
        let factor = value / (30 * 30);

        let xs=[];
        let ys=[];
        for(let i=0; i<30; i++) {
            let pointTime = timeNowMillis - (30 - i) * (24 * 3600 * 1000);
            let x = moment(pointTime).format('YYYY-MM-DD');
            let y = factor * (i * i);
            xs.push(x);
            ys.push(y);
        }

        echartRef.current.setOption({
            xAxis: {
                data: xs,
            },
            series: [
                {
                    data: ys,
                    markPoint: {
                        symbol: 'pin',
                        symbolSize: 20,
                        itemStyle: {
                            color: '#81B337',
                        },
                        data: [{ 
                            name: '当前', 
                            value: ys[ys.length-1].toFixed(2), 
                            xAxis: xs[xs.length-1], 
                            yAxis: ys[ys.length-1]
                        }],
                        label: {
                            color: '#FFFFFF',
                            position: 'top',
                        }
                    },
                },
            ]
        });
        echartRef.current.resize();
    };

    let formatTimestampAxis = (value)=> {
        let text = moment(value).format('YYYY/MM/DD HH:mm:ss');
        text=text.replace(' ', '\n');
        return text;
    }
    
    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        if (dom == null) {
            // element unmount event
            // let echarts = echartsRef.current[index];
            // echarts.dispose();
            return;
        }
        if (echarts.getInstanceByDom(dom)) {
            return;
        }

        // save to reference
        echartDomRef.current = dom;

        // init echarts
        let chart = echarts.init(dom);
        echartRef.current = chart;

        // init options
        let option = {
            backgroundColor: 'transparent',
            tooltip: {
                show: false
            },
            grid: {
                x: 50,
                y: 20,
                x2: 20,
                y2: 40,
            },
            xAxis: {
                name: '',
                type: 'category',
                axisLabel: {
                    show: true,
                    fontSize: 12,
                    color: '#FFFFFF',
                    interval: 10,
                    margin: 10,
                },
            },
            yAxis: {
                type: 'value',
                name: '',
                boundaryGap: [0, '100%'],
                axisTick: {
                    show: true,
                },
                axisLine: {
                    show: true,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    }
                },
                splitNumber: 2,
                nameTextStyle: {
                    align: 'left',
                },
                axisLabel: {
                    show: true,
                    fontSize: 12,
                    color: '#FFFFFF',
                },
                min: 0.0,
                max: 1.0,
            },
            series: [
                {
                    name: '设备健康度',
                    type: 'line',
                    color: '#6482E6',
                    smooth: true,
                    showSymbol: false,
                    data: [],
                    shadowBlur: 20,
                    markLine: {
                        data: [
                            {
                                name: '告警',
                                yAxis: '0.5', 
                                lineStyle: {
                                    color: '#FFEB3B',
                                },
                                label: {
                                    position: 'middle',
                                    formatter: '{c} {b}',
                                    color: '#FFEB3B',
                                    textBorderColor: 'transparent',
                                }
                            },
                            {
                                name: '危险',
                                yAxis: '0.8', 
                                lineStyle: {
                                    color: '#EA0001',
                                },
                                label: {
                                    position: 'middle',
                                    formatter: '{c} {b}',
                                    color: '#EA0001',
                                    textBorderColor: 'transparent',
                                }
                            }
                        ]
                    },
                    areaStyle: {
                        opacity: 0.4,
                    },
                }
            ],
        };

        chart.setOption(option);
        chart.group='DeviceHealthGroup';
        echarts.connect('DeviceHealthGroup');
    }

    // effect
    useEffect(()=> {
        loadDeviceView();
    }, []);

    // render
    

    return (
        <DeviceHealthWrapper>
            <Title>特征值</Title>
            <ChartWrapper>
                <EChartsWrapper ref={(dom)=> initECharts(dom)}>
                </EChartsWrapper>
            </ChartWrapper>
        </DeviceHealthWrapper>
    )
}

export default DeviceHealth;
