import { HashRouter, Route, Routes } from "react-router-dom";
import { ContentWrapper, StatusBarWrapper, MainLayoutWrapper, MainWrapper, SideBarWrapper, RouterWrapper, BigScreenLayoutWrapper, MainContentLayoutWrapper } from "./Main.styled";
import Dashboard from "../dashboard/Dashboard";
import DeviceManagement from "../device-management/DeviceManagement";
import DeviceGroupManagement from "../device-group/DeviceGroupManagement";
import DeviceTemplateManagement from "../device-template-management/DeviceTemplateManagement";
import ProductManagement from "../product-management/ProductManagement";
import MainSideBar from "./components/sidebar/MainSideBar";
import AlarmEventManagement from "../alarm-event-management/AlarmEventManagement";
import AlarmRuleManagement from "../alarm-rule-management/AlarmRuleManagement";
import DeviceOnlineOfflineHistoryManagement from "../device-online-offline-history/DeviceOnlineOfflineHistoryManagement";
import UserManagement from "../user-management/UserManagement";
import RoleManagement from "../role-management/RoleManagement.";
import Menu from "./menu/Menu";
import MainStatusBar from "./components/status-bar/MainStatusBar";
import FirmwareManagement from "../firmware-management/FirmwareManagement";
import FirmwarePushManagement from "../firmware-push-managment/FirmwarePushManagement";
import { OverviewBigScreen } from "../big-screen/overview/OverviewBigScreen";
import { isMobile, useMobileOrientation } from "react-device-detect";
import DeviceDetailsScreen from "../big-screen/device/DeviceDetailsScreen";
import DeviceMetricsManagement from "../device-metrics-management/DeviceMetricsManagement";
import ScaleUtils from "../../util/ScaleUtils";

interface MainProps {

}

const Main: React.FC<MainProps> = (props)=> {

    // menu 
    let menu = Menu.getMainMenu();

    // router
    let routes = [
        {
            path: '/',
            element: <DeviceManagement />,
        },
        {
            path: '/index.html',
            element: <Dashboard />,
        },
        {
            path: '/dashboard',
            element: <Dashboard />,
        },
        {
            path: '/big-screen/overview',
            element: <OverviewBigScreen />
        },
        {
            path: '/big-screen/device-details/:deviceId',
            element: <DeviceDetailsScreen />
        },
        {
            path: '/big-screen-management',
            // element: <BigScreenManagement />
            element: <div>Big Screen Management ...</div>
        },
        {
            path: '/device-management/*',
            element: <DeviceManagement />
        },
        {
            path: '/device-group-management',
            element: <DeviceGroupManagement />
        },
        {
            path: '/device-template-management/*',
            element: <DeviceTemplateManagement />
        },
        {
            path: '/product-management',
            element: <ProductManagement />
        },
        {
            path: '/alarm-events',
            element: <AlarmEventManagement />
        },
        {
            path: '/alarm-rules',
            element: <AlarmRuleManagement />
        },
        {
            path: '/firmware-management',
            element: <FirmwareManagement />
        },
        {
            path: '/firmware-push-management',
            element: <FirmwarePushManagement />
        },
        {
            path: '/statistics',
            element: <div>System Statistics ...</div>
        },
        {
            path: '/device-online-offline-history',
            element: <DeviceOnlineOfflineHistoryManagement />
        }, 
        {
            path : '/device-metrics-management',
            element: <DeviceMetricsManagement />
        },
        {
            path : '/user-management',
            element: <UserManagement />
        },
        {
            path: '/role-management',
            element: <RoleManagement />
        },
    ];

    let { isLandscape } = useMobileOrientation();

    function setViewPort() {
        let viewport = document.querySelector("meta[name=viewport]") as any;
        if (!viewport){
            // in case there is no view port meta tag creates one and add it to the head
            viewport=document.createElement('meta') as any;
            viewport.name = "viewport";
            document.getElementsByTagName('head')[0].appendChild(viewport);
        }
        
        const content = `width=device-width, initial-scale=${isLandscape ? '0.5' : '0.25'}, user-scalable=yes,shrink-to-fit=no`;
        // this is where the magic happens by changing the vewport meta tag
        viewport.setAttribute('content', content);
    }

    function setBigScreenScale(wrapperDom: HTMLElement) {
        if (wrapperDom == null) {
            return;
        }
        let screenWidth = window.screen.width / window.devicePixelRatio;
        let screenHeight = window.screen.height / window.devicePixelRatio;

        let widthRatio = screenWidth / 1920;
        let heightRatio = screenHeight / 1080;
        let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        wrapperDom.style.transform = `scale(${ratio})`;
        wrapperDom.style.transformOrigin = 'left top';
        ScaleUtils.setGlobalScale(ratio);
    }

    function setMainScale(wrapperDom: HTMLElement) {
        if (wrapperDom == null) {
            return;
        }
        let screenWidth = window.screen.width / window.devicePixelRatio;
        let screenHeight = window.screen.height / window.devicePixelRatio;

        let widthRatio = screenWidth / 1920;
        let heightRatio = screenHeight / 1080;
        let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        wrapperDom.style.transform = `scale(${ratio})`;
        wrapperDom.style.transformOrigin = 'center top';
    }

    function disableSwipeBack() {
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, null, window.location.href);
        });
    }
    
    if (isMobile) {
        setViewPort();
        disableSwipeBack();
    }

    // render
    return (
        <HashRouter>
            <MainWrapper>
                <Routes>
                    <Route path="/big-screen/*" element={
                        <BigScreenLayoutWrapper ref={(dom)=> setBigScreenScale(dom)}>
                            <Routes>
                                <Route path="/overview" element={<OverviewBigScreen />} />
                                <Route path="/device-details/:deviceId" element={<DeviceDetailsScreen />} />
                            </Routes>
                        </BigScreenLayoutWrapper>
                    } />

                    <Route path="/*" element={
                        <MainLayoutWrapper ref={(dom)=> setMainScale(dom)}>
                            <MainContentLayoutWrapper>
                                <SideBarWrapper>
                                    <MainSideBar />
                                </SideBarWrapper>

                                <ContentWrapper>
                                    <StatusBarWrapper>
                                        <MainStatusBar />
                                    </StatusBarWrapper>
                        
                                    <RouterWrapper>
                                        <Routes>
                                        {
                                            routes.map((route)=> {
                                                return (
                                                    <Route key={route.path} path={route.path} element={route.element}></Route>
                                                )
                                            })
                                        }
                                        </Routes>
                                    </RouterWrapper>
                                </ContentWrapper>
                            </MainContentLayoutWrapper>
                        </MainLayoutWrapper>
                    } />
                </Routes>
            </MainWrapper>
        </HashRouter>
    )
}

export default Main;
