import StringUtils from "../../../util/StringUtils";
import { DeviceStatusWrapper, OfflineStatus, OnlineStatus, StatusText } from "./DeviceStatus.styled";

interface DeviceStatusProps {
    status: string;
}

let DeviceStatus: React.FC<DeviceStatusProps> = (props)=> {
    let getDeviceStatus = (status: string)=> {
        if (status=='ONLINE') {
            return <OnlineStatus>
                <StatusText>在线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-online"></use>
                </svg>
            </OnlineStatus>;
        } else if(status=='OFFLINE') {
            return <OfflineStatus>
                <StatusText>离线</StatusText>
                <svg className="icon" aria-hidden="true" style={{color: '#F97D5B', fontSize: '18px'}}>
                    <use xlinkHref="#icon-offline"></use>
                </svg>
            </OfflineStatus>;
        }
        return 'N/A';
    };

    return <DeviceStatusWrapper>
        { getDeviceStatus(props.status) }
    </DeviceStatusWrapper>
};

class DeviceStatusUtils {
    public static getDeviceMarkerColor(status: string): string {
        switch(StringUtils.nonull(status).toUpperCase()) {
            case 'ONLINE': 
                return 'rgba(28, 178, 115, 0.5)';
            case 'OFFLINE':
                return 'rgba(249, 125, 90, 0.5)';
            default:
                return 'rgba(249, 125, 90, 0.5)';
        }
    };
}

export default DeviceStatus;
export {DeviceStatus, DeviceStatusUtils};
