import styled from "styled-components";

export const FirmwarePushTaskDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 700px;
    box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

export const TaskOverview = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

export const TaskOverviewLeft = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 50px;
`;

export const TaskOverviewRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const FirmwareModelVersion = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ProductModel = styled.div`
    font-weight: bold;
    font-size: 24px;
`;

export const Version = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-left: 10px;
`;

export const TaskStatus = styled.div`

`;

export const InfoItemRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 250px;
    padding-right: 10px;
    box-sizing: border-box;
`;

export const InfoLabel = styled.span`
    font-size: 12px;
    color: #555555;
`;

export const InfoValue = styled.span`
    font-size: 14px;
    margin-top: 4px;
    color: #101010;
    white-space: nowrap;
    text-overflow: ellipse;
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px;
`;


export const InfoGroupSeparator = styled.div`
    border-top: 1px solid #CCCCCC;
    margin-top: 15px;
`;

export const NodeProgressList = styled.div`

`;

export const NodeProgressListHeader = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 5px 0px;
    border-bottom: 1px solid #CCCCCC;
    background-color: #FAFAFA;
`;


export const NodeProgressListItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    border-bottom: 1px solid #CCCCCC;

    &:hover {
        background-color: #FAFAFA;
    }
`;


export const NodeSN = styled.div`
    width: 200px;
    text-align: center;
`;

export const NodeDeviceName = styled.div`
    width: 200px;
    text-align: center;
`;

export const NodeOtaProgress = styled.div`
    flex: 1 1 auto;
    text-align: center;
    padding: 0px 20px;
`;

export const NodeOtaStatus = styled.div`
    width: 100px;
    padding: 0px 10px;
    text-align: center;
    box-sizing: border-box;
`;

