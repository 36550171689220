import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/device-template";

class DeviceTemplateApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getAllDeviceTemplateViews(): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/device-template-view/all');
    }

    public async saveSettings(deviceTemplateId: string, type: string, settings: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/default-device-settings/save', {
            deviceTemplateId: deviceTemplateId,
            groupSettingsType: type,
            groupSettings: settings,
        });
    }

    public async saveConfiguration(deviceTemplateId: string, configuration: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/configuration/save', {
            deviceTemplateId: deviceTemplateId,
            configuration: configuration,
        });
    }

    public async getDeviceTemplateViewByDeviceId(deviceTemplateId: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/device-template-view/device-template-id/' + deviceTemplateId);
    }

}

export default DeviceTemplateApi;
