class Menu {
    groups: MenuGroup[];

    public static getMainMenu(): Menu {
        let deviceGroup = {
            name: '设备管理',
            items: [
                {
                    name: '设备列表',
                    path: '/device-management',
                },
            ],
        };

        let systemSettingsGroup = {
            name: '系统设置',
            items: [
                {
                    name: '用户管理',
                    path: '/user-management'
                },
            ],
        };

        return {
            groups: [
                deviceGroup,
                systemSettingsGroup,
            ],
        }
    }
}

class MenuGroup {
    name: string;
    items: MenuItem[];
}

class MenuItem {
    name: string;
    path: string;
}

export default Menu;
export { Menu, MenuGroup, MenuItem};
