
import styled from "styled-components";

export const DeviceListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
    box-sizing: border-box;
    
    &::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
`;

export const ListContent = styled.div`
    flex: 1 1 auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    
    &::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
`;

export const DeviceItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        background-color: rgba(180, 180, 180, 0.2);
    }
`;

export const DeviceName = styled.div`
    flex: 0 0 auto;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const DeviceTemplateName = styled.div`
    flex: 0 0 auto;
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const DeviceStatus = styled.div`
    flex: 0 0 auto;
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
`;

export const DeviceRssi = styled.div`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
`;

export const DeviceRssiValue = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
`;

export const DeviceRssiText = styled.div`
    display: inline-block;
`;

export const DeviceRssiIcon = styled.div`
    display: inline-flex;
    align-items: flex-end;
    height: 10px;
    margin: 0px 0px 3px 5px;
`;

export const StatusText = styled.div`
    margin: 0px 5px;
`;

export const OnlineStatus = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const OfflineStatus = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
`;