interface AlarmRuleManagementProps {

}

const AlarmRuleManagement: React.FC<AlarmRuleManagementProps> = (props)=> {
    // render
    return (
        <div>Alarm Rules, Developing...</div>
    );
}

export default AlarmRuleManagement;
