import styled from "styled-components";

export const DeviceVariablesWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ToolBarWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 20px;
`;

export const ToolBarLeftWrapper = styled.div`
    flex: 0 0 auto;
`

export const ToolBarRightWrapper = styled.div`
    flex: 0 0 auto;
`

export const TableWrapper = styled.div`
    flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
`;

export const EditVariableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;  
`;

export const TitleWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const EditVariableFormLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin: 0px -10px;
    box-sizing: border-box;
`;

export const EditVariableFormRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 0px;

    & .ant-form-item {
        flex: 1 1 0;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        min-width: 0px;
    }

    & .ant-form-item-label {
        padding-left: 5px !important;
    }
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px;
`;
