import { useEffect, useRef, useState } from "react";
import { ChartHeader, ChartHeaderColorBlock, ChartInfo, ChartName, ChartUnit, ChartWrapper, ChartsWrapper, EChartsWrapper, FilterBar, FilterItem, FilterItemLabel, HistoryDeviceMetricsWrapper, SearchResult } from "./DeviceMetricsManagement.styled";
import { Button, DatePicker, Segmented, Select, Space, Table } from "antd";
import { ExportOutlined, LineChartOutlined, SearchOutlined, TableOutlined } from "@ant-design/icons";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import * as echarts from "echarts";
import ViewStateService from "../../services/view-state/ViewStateService";
import SeriesColorUtils from "../../util/SeriesColorUtils";
import StringUtils from "../../util/StringUtils";
import DeviceMetricApi from "../../api/DeviceMetricApi";
import DeviceApi from "../../api/DeviceApi";

interface DeviceMetricsManagementProps {
}

const DeviceMetricsManagement: React.FC<DeviceMetricsManagementProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();
    let deviceMetricApi = new DeviceMetricApi();

    // reference
    let echartsDomsRef = useRef<HTMLElement[]>([]);
    let echartsRef =useRef<echarts.ECharts[]>([]);

    // state
    let [devices, setDevices] = useState([]);
    let [deviceTemplateProperties, setDeviceTemplateProperties] = useState([]);

    let [targetedDeviceId, setTargetDeviceId] = useState(null);
    let [targetedPropertyKeys, setTargetedPropertyKeys] = useState([]);
    let [startTime, setStartTime] = useState(dayjs().add(-1, 'd'));
    let [endTime, setEndTime] = useState(dayjs());
    let [aggregateType, setAggregateType] = useState('RAW');
    let [timeScale, setTimeScale] = useState('NONE');
    let [displayType, setDisplayType] = useState('CHARTS');

    // metrics
    let [deviceMetricsSeriesList, setDeviceMetricsSeriesList] = useState([]);
    let [deviceMetricsTableData, setDeviceMetricsTableData] = useState([]);
    let deviceMetricsSeriesProperties = deviceMetricsSeriesList.map(e=> e.property);

    // computed
    let deviceOptions = devices.map((e)=> {
        return {
            label: e.name,
            value: e.id,
        };
    });

    let propertyOptions = deviceTemplateProperties.map((e)=> {
        return {
            label: e.name,
            value: e.key,
        }
    });
    propertyOptions.splice(0, 0, {label: '全部', value: 'ALL'});

    // events
    let onTargetedDeviceChange = async (selectedValue: any)=> {
        // set targeted
        setTargetDeviceId(selectedValue);

        // device view
        let deviceView: any = (await deviceApi.getDeviceViewByDeviceId(selectedValue)).data.data;
        let deviceTemplateConfiguration = JSON.parse(deviceView.deviceTemplate.configuration);
        let deviceTemplateProperties = deviceTemplateConfiguration.properties;
        setDeviceTemplateProperties(deviceTemplateProperties);
    };

    let onTargetedPropertiesChange = (selectedValues: string[])=> {
        let newHasAll = selectedValues.indexOf('ALL') >= 0;
        let hasAll= targetedPropertyKeys.indexOf('ALL') >= 0;
        
        let newSelectedProperties = [].concat(selectedValues);
        if (newHasAll && hasAll) {
            if(selectedValues.length < targetedPropertyKeys.length) {
                // item unselected
                newSelectedProperties = newSelectedProperties.filter((e)=> e != 'ALL');
            }
        } else if(newHasAll) {
            // select all
            for(let deviceTemplateProperty of deviceTemplateProperties) {
                if(newSelectedProperties.indexOf(deviceTemplateProperty.key) < 0) {
                    newSelectedProperties.push(deviceTemplateProperty.key);
                }
            }
        } else if(hasAll) {
            // has unselected items
            newSelectedProperties = newSelectedProperties.filter((e)=> e!= 'ALL');
        } else if (selectedValues.length == propertyOptions.length) {
            // all selected
            newSelectedProperties.push('ALL');
        }
        setTargetedPropertyKeys(newSelectedProperties);
    };

    let onDateTimeRangeChange = (dateTimeRangeValue: any[])=> {
        setStartTime(dateTimeRangeValue[0]);
        setEndTime(dateTimeRangeValue[1]);
    };

    let onAggregateTypeChanged = (selectedValue: string)=> {
        setAggregateType(selectedValue);
    };

    let onTimeScaleChanged = (selectedValue: string)=> {
        setTimeScale(selectedValue);
    };

    let onDisplayTypeChange = (selectedValue: string)=> {
        setDisplayType(selectedValue);
    };

    let onQueryButtonClick = async ()=> {
        // process start/end time
        let startTimestamp = null;
        if (startTime!=null) {
            startTimestamp = dayjs(startTime).toDate().getTime();
        }
        let endTimestamp = null;
        if (endTime!=null) {
            endTimestamp = dayjs(endTime).toDate().getTime();
        }

        // fetch data
        let deviceMetricsSeriesList = (await deviceMetricApi.queryHistoricalDeviceMetricsSeries({
            deviceId: targetedDeviceId,
            properties: targetedPropertyKeys,
            startTime: startTimestamp,
            endTime: endTimestamp,
            aggregateType: aggregateType,
            timeScale: timeScale,
        })).data.data;

        // table data
        let deviceMetricsTableData = [];
        let timestamp2RowData = {};
        for(let deviceMetricsSeries of deviceMetricsSeriesList) {
            let propertyKey = deviceMetricsSeries.property.key;
            for(let metric of deviceMetricsSeries.metrics) {
                let rowData = timestamp2RowData['' + metric.timestamp];
                if (rowData==null) {
                    rowData = {timestamp: Number(metric.timestamp)};
                    timestamp2RowData['' + metric.timestamp] = rowData;
                    deviceMetricsTableData.push(rowData);
                }
                rowData[propertyKey] = metric.value;
            }
        }
        deviceMetricsTableData = deviceMetricsTableData.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        // update data
        setDeviceMetricsSeriesList(deviceMetricsSeriesList);
        setDeviceMetricsTableData(deviceMetricsTableData);
    };

    let onExportButtonClick = async ()=> {
        // process start/end time
        let startTimestamp = null;
        if (startTime!=null) {
            startTimestamp = dayjs(startTime).toDate().getTime();
        }
        let endTimestamp = null;
        if (endTime!=null) {
            endTimestamp = dayjs(endTime).toDate().getTime();
        }

        // fetch data
        let deviceMetricsExportTask = (await deviceMetricApi.exportHistoricalDeviceMetrics({
            deviceId: targetedDeviceId,
            properties: targetedPropertyKeys,
            startTime: startTimestamp,
            endTime: endTimestamp,
            aggregateType: aggregateType,
            timeScale: timeScale,
        })).data.data;

        // fire new task event
        ViewStateService.getInstance().fireNewTaskListeners();
    };

    // init ECharts
    let formatTimestampAxis = (value)=> {
        let text = moment(value).format('YYYY/MM/DD HH:mm:ss');
        text=text.replace(' ', '\n');
        return text;
    }

    let initECharts = (property: any, dom: HTMLElement)=> {
        let propertyIndex = deviceMetricsSeriesProperties.map(e=>e.key).indexOf(property.key);

        if (dom == null) {
            // element unmount event
            let echarts = echartsRef.current[propertyIndex];
            echarts.dispose();
            return;
        }
        if (echarts.getInstanceByDom(dom)) {
            return;
        }

        // save to reference
        echartsDomsRef.current[propertyIndex]=dom;

        // init echarts
        let chart = echarts.init(dom);
        echartsRef.current[propertyIndex]=chart;

        // series
        let deviceMetricsSeries = deviceMetricsSeriesList.filter(e=>e.property.key==property.key)[0];

        let seriesData = [];
        let metrics = deviceMetricsSeries.metrics;
        let lastMetricTimestamp: number = null;
        let paddingGapMillis = 5 * 10000;
        for (let i = 0; i < metrics.length; i++) {
            let metric = metrics[i];
            
            // pad data point
            if (lastMetricTimestamp !=null && Math.abs(metric.timestamp - lastMetricTimestamp) > paddingGapMillis) {
                // down data point
                let downDataPoint = {name: '' + (Number(lastMetricTimestamp)+1), value: [(Number(lastMetricTimestamp)+1), 0]};
                seriesData.push(downDataPoint);

                // up data point
                let upDataPoint: any = {name: '' + (Number(metric.timestamp)-1), value: [(Number(metric.timestamp)-1), 0]};
                seriesData.push(upDataPoint);
            }
            lastMetricTimestamp = metric.timestamp;

            // add to series data
            seriesData.push({
                name: metric.timestamp + '',
                value: [Number(metric.timestamp), Number(metric.value)],
            });
        }

        // init options
        let option = {
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    animation: false
                },
                backgroundColor: "rgba(0,0,0,0.7)",
                confine: true,
                formatter: function(params) {
                    let seriesContent = '';
                    for(let param of params) {
                        seriesContent += `
                            <div style="color: #EEEEEE;">
                                <div style="margin: 0px 0px 5px 0px;">
                                    <div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 5px; background-color: ${param.color}"></div>
                                    <div style="display: inline-block">${param.seriesName}</div>
                                </div>
                                <div style="padding: 5px 0px;">
                                    <span style="display: inline-block; width: 50px;">测量值:</span> 
                                    <span style="display: inline-block">${param.value[1]} ${property.unit}</span>
                                </div>
                                <div>
                                    <span style="display: inline-block; width: 50px;">时间:</span> 
                                    <span style="display: inline-block">${moment(param.value[0]).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                            </div>
                        `;
                        break;
                    }
                    let content=`
                        <div style="width: 220px;">
                            ${seriesContent}
                        </div>
                    `;
                    return content;
                },
            },
            grid: {
                x: 50,
                y: 20,
                x2: 20,
                y2: 40,
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    },
                },
                splitNumber: 2,
                axisLabel: {
                    show: true,
                    formatter: function (value, index) {
                        return formatTimestampAxis(value);
                    }
                },
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    }
                },
                nameTextStyle: {
                    align: 'left',
                },
            },
            dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                }
            ],
            series: [
                {
                    name: property.name,
                    type: 'line',
                    color: SeriesColorUtils.getSeriesColor(propertyIndex),
                    smooth: false,
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.2,
                    },
                    data: seriesData,
                    shadowBlur: 20,
                }
            ],
        };

        chart.setOption(option);
        chart.group = 'DeviceRealtimeMetrics';
        echarts.connect('DeviceRealtimeMetrics');
    }

    // load devices
    let loadDevices = async ()=> {
        let devices = (await deviceApi.getAll()).data.data;
        setDevices(devices);
    };

    // effect
    useEffect(()=> {
        loadDevices();
    }, []);

    // render
    let queryExportDisabled = ()=> {
        if (targetedPropertyKeys.filter(e=> e!=null).length > 0 && startTime!=null) {
            return false;
        }
        return true;
    };

    let formatTimestamp = (timestamp)=> {
        return moment(Number(timestamp)).format('YYYY/MM/DD HH:mm:ss');
    };

    let columns: any[] = [
        {
            title: '数据时间',
            dataIndex: 'deviceMetric.timestamp',
            key: 'deviceMetric.timestamp',
            align: 'center',
            render: (text, record, index)=> {
                return formatTimestamp(record.timestamp);
            }
        },
    ];

    for(let targetedPropertyKey of targetedPropertyKeys) {
        if (targetedPropertyKey == 'ALL') {
            continue;
        }
        let targetedProperty = deviceTemplateProperties.filter(e=>e.key == targetedPropertyKey)[0];
        let column = {
            title: targetedProperty.name,
            dataIndex: targetedProperty.key,
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record[targetedProperty.key]}</span>
            }
        }
        if (StringUtils.isEmpty(targetedProperty.unit)) {
            column.title = column.title + `（${StringUtils.emptyToSlash(targetedProperty.unit)}）`;
        }
        columns.push(column);
    }

    let paddingChartWrapper = [];
    for(let i=deviceMetricsSeriesList.length; i<4; i++) {
        paddingChartWrapper.push(i);
    }

    return (
        <HistoryDeviceMetricsWrapper>
            <FilterBar>
                <Space size="small">
                    <FilterItem>
                        <FilterItemLabel>目标设备</FilterItemLabel>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            filterSort={(a, b) => a <= b ? -1 : 1}
                            style={{ width: '120px' }}
                            placeholder="请选择目标设备"
                            value={targetedDeviceId}
                            onChange={onTargetedDeviceChange}
                            options={deviceOptions}
                        />
                    </FilterItem>
                    <FilterItem>
                        <FilterItemLabel>设备变量</FilterItemLabel>
                        <Select
                            mode="multiple"
                            allowClear
                            maxTagCount={2}
                            style={{ width: '120px' }}
                            placeholder="请选择目标变量"
                            defaultValue={targetedPropertyKeys}
                            value={targetedPropertyKeys}
                            onChange={onTargetedPropertiesChange}
                            options={propertyOptions}
                        />
                    </FilterItem>

                    <FilterItem>
                        <FilterItemLabel>时间范围</FilterItemLabel>
                        <DatePicker.RangePicker
                            style={{width: '288px'}}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            defaultValue={[dayjs().add(-1, 'd'), dayjs()]}
                            value={[startTime, endTime]}
                            allowEmpty={[false, true]}
                            onOk={onDateTimeRangeChange}
                        />
                    </FilterItem>

                    <FilterItem>
                        <FilterItemLabel>数据聚合</FilterItemLabel>
                        <Select
                            style={{ width: '100px' }}
                            placeholder="请选择数据聚合类型"
                            defaultValue={'RAW'}
                            value={aggregateType}
                            onChange={onAggregateTypeChanged}
                            options={[
                                {label: '原始数据', value: 'RAW'},
                                {label: '最大值', value: 'MAX'},
                                {label: '最小值', value: 'MIN'},
                                {label: '平均值', value: 'AVG'},
                            ]}
                        />
                    </FilterItem>

                    <FilterItem>
                        <FilterItemLabel>时间维度</FilterItemLabel>
                        <Select
                            style={{ width: '90px' }}
                            placeholder="请选择时间维度"
                            defaultValue={'NONE'}
                            value={timeScale}
                            onChange={onTimeScaleChanged}
                            options={[
                                {label: '无', value: 'NONE'},
                                {label: '1分钟', value: '1MIN'},
                                {label: '5分钟', value: '5MIN'},
                                {label: '30分钟', value: '30MIN'},
                                {label: '1小时', value: '1HOUR'},
                            ]}
                        />
                    </FilterItem>

                    <FilterItem>
                    <FilterItemLabel>展示方式</FilterItemLabel>
                        <Segmented
                            options={[
                                { label: '图表', value: 'CHARTS', icon: <LineChartOutlined /> },
                                { label: '表格', value: 'TABLE', icon: <TableOutlined /> },
                            ]}
                            value={displayType}
                            onChange={onDisplayTypeChange}
                        />
                    </FilterItem>
                </Space>

                <Space size="small">
                    <Button 
                        type="primary" 
                        icon={<SearchOutlined style={{marginTop: '3px'}}/>} 
                        onClick={onQueryButtonClick}
                        className="query-button"
                        disabled={queryExportDisabled()}
                    >查询</Button>
                    <Button 
                        type="primary" 
                        icon={<ExportOutlined />} 
                        onClick={onExportButtonClick}
                        disabled={queryExportDisabled()}
                    >导出</Button>
                </Space>
            </FilterBar>

            {
                deviceMetricsSeriesList.length > 0 && (
                    displayType == 'TABLE' 
                    ? <SearchResult>
                            <Table
                                rowKey={(record)=> record.timestamp}
                                columns={columns} 
                                dataSource={deviceMetricsTableData}
                            />
                    </SearchResult>

                    : <ChartsWrapper>
                    {
                        deviceMetricsSeriesProperties.map((e, index)=> {
                            return (
                                <ChartWrapper key={e.key}>
                                    <ChartHeader>
                                        <ChartName>{e.name}</ChartName>
                                        <ChartHeaderColorBlock style={{backgroundColor: SeriesColorUtils.getSeriesColor(index)}} />
                                        <ChartInfo>
                                            <ChartUnit>单位：{StringUtils.emptyToSlash(e.unit)}</ChartUnit>
                                        </ChartInfo>
                                    </ChartHeader>
                                    <EChartsWrapper ref={(dom)=> initECharts(e, dom)}>
                                    </EChartsWrapper>
                                </ChartWrapper>
                            )
                        })
                    }
                    {
                        paddingChartWrapper.map((e)=> {
                            return (
                                <ChartWrapper key={e}>
                                </ChartWrapper>
                            )
                        })
                    }
                    </ChartsWrapper>
                )
            }
        </HistoryDeviceMetricsWrapper>
    )
}

export default DeviceMetricsManagement;

