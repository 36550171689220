import styled from "styled-components";
import LoginIllustration from './assets/images/login.png';

export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #016EEB;
    background-image: url(${LoginIllustration});
    background-size: cover;
`;

export const LoginDialogWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    margin-left: 35vw;
`;

export const LoginFormWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: stretch;
`;

export const LoginForm = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    width: 520px;
    padding: 30px;
    background-color: #FFFFFF;
`;

export const LoginFormTitle = styled.div`
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 40px;
    font-weight: bold;
    color: #101010;
    text-align: center;
    letter-spacing: 6px;
`;

export const LoginFormTitleEn = styled.div`
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: bold;
    color: #101010;
    text-align: center;
`;

export const LoginFormDescription = styled.div`
    margin-top: 10px;
    font-size: 13px;
    line-height: 200%;
    color: #333333;
    text-align: justify;
`;

export const FormItemWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const ErrorMessage = styled.div`
    height: 20px;
    margin-top: 20px;
    color: #EE0000;
`;

export const FormItemLabel = styled.div`
    margin-left: 10px;
    font-size: 14px;
    letter-spacing: 2px;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const FormItemInput = styled.input`
    box-sizing: border-box;
    padding: 0px 20px;
    height: 50px;
    margin-top: 10px;
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    color: #101010;
    font-size: 14px;
    letter-spacing: 1px;
`;

export const FormSubmit = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 10px;
    text-align: center;
    color: #FFFFFF;
    background-color: #016EEB;

    &:hover {
        background-color: #0077FF;
    }
`;

export const LoginPicture = styled.div`
    flex: 1 1 auto;
    background-image: url(${LoginIllustration});
    background-size: cover;
    background-position: center center;
`;
