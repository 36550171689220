import { useEffect, useState } from "react";
import { ClockWrapper, DateAndDayWrapper, DateWrapper, DayWrapper, TimeWrapper } from "./Clock.styled";
import moment from "moment";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";

interface ClockProps {

}

export const Clock: React.FC<ClockProps> = (props)=> {

    // state
    let [now, setNow] = useState(new Date());

    // effect
    useEffect(()=> {
        let refreshInterval = setInterval(()=> {
            setNow(new Date());
        }, 1000);

        return ()=> {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        }
    }, []);

    let days = [
        '星期天',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
    ];

    // render
    return (
        <ClockWrapper>
            <TimeWrapper>{moment(now).format('HH:mm:ss')}</TimeWrapper>
            <DateAndDayWrapper>
                <DayWrapper><ClockCircleOutlined /> {days[now.getDay()]}</DayWrapper>
                <DateWrapper>{moment(now).format('YYYY/MM/DD')}</DateWrapper>
            </DateAndDayWrapper>
        </ClockWrapper>
    )
};
