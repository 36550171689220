import styled from "styled-components"

export const TaskListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 360px;
    padding-bottom: 10px;
`;

export const ExportDeviceMetricsTask = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5px 5px;
    border-radius: 5px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const TaskInformation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0px 3px;
`;

export const TaskName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DownloadLink = styled.a`

`;

export const TaskProgress = styled.div`
    position: relative;
    top: -2px;
`;

export const NoTaskTips = styled.div`
    text-align: center;
    padding: 20px 0;
    color: #AAAAAA;
`;