import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class ProfileApi {
    
    public async getProfile(): Promise<AxiosResponse<any>> {
        return AxiosService.get('/profile');
    }

}

export default ProfileApi;