import styled from "styled-components";

export const DeviceInformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 5px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const InfoGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const InfoGroupSeparator = styled.div`
    border-top: 1px solid #CCCCCC;
    margin-top: 15px;
`;

export const InfoGroupTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 15px 20px;
`;

export const InfoGroupContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
`;

export const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 230px;

    &:first-child {
        margin-top: 0px;
        width: 200px;
    }
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 60px;
`;

export const InfoLabel = styled.span`
    font-size: 12px;
    color: #555555;
`;

export const InfoValue = styled.span`
    font-size: 14px;
    margin-top: 4px;
    color: #101010;
    display: flex;
    align-items: center;
`;

export const ProductModelPicture = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 8px solid #CCCCCC;
    background-size: cover;
    background-position: center center;
`;


export const DeviceLocationMapWrapper = styled.div`
    width: 500px;
    height: 280px;
    border-radius: 5px;
    overflow: hidden;
`;

export const EditInfoButton = styled.div`

`;

export const ViewDeviceLink = styled.a`
    text-decoration: none;
    padding: 1px 10px 0px 10px;
`
