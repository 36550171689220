import { ReactNode, memo, useEffect, useState } from "react";
import SessionService, { SessionType } from "../../services/session/SessionService";
import ProfileApi from "../../api/ProfileApi";
import Login from "./Login";
import UrlUtils from "../../util/UrlUtils";

interface LoginProps {
    children: ReactNode[]
}

const LoginInterceptor: React.FC<LoginProps>= memo((props) => {
    // status
    let [status, setStatus] = useState(Status.INIT);

    // verify session
    let sessionService = SessionService.getInstance();
    if (status == Status.INIT) {
        let search = window.location.search;
        if (!search) {
            let hash = window.location.hash;
            if (hash && hash.indexOf('?') >=0) {
                search = hash.substring(hash.indexOf('?'));
            }
        }
        let queryParams = UrlUtils.getURLParams(search);
        let authorization = queryParams.get('t');
        if (authorization) {
            sessionService.setSessionType(SessionType.GUEST);
            sessionService.setAuthorization(authorization);
        }
        
        authorization = sessionService.getAuthoriazation();
        if(authorization) {
            let getProfile = async ()=> {
                let profileResponse = await new ProfileApi().getProfile();
                sessionService.setCurrentUserProfile(profileResponse.data.data);
                setStatus(Status.AUTHED);
            }
            getProfile().catch(()=> {
                setStatus(Status.NOT_AUTHED);
                sessionService.setSessionType(SessionType.NORMAL);
            });
        } else {
            setStatus(Status.NOT_AUTHED);
        }
    }

    // events
    useEffect(()=> {
        let sessionChangeListener = ()=> {
            setStatus(Status.INIT);
        };
        sessionService.addSessionChangeListener(sessionChangeListener);
        return ()=> {
            sessionService.removeSessionChangeListener(sessionChangeListener);
        }
    }, []);

    // render
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {
                (()=> {
                    if (status == Status.INIT) {
                        return null;
                    } else if(status == Status.NOT_AUTHED) {
                        return <Login />
                    } else {
                        return props.children;
                    }
                })()
            }
        </div>
    )
});

enum Status {
    INIT,
    NOT_AUTHED,
    AUTHED
}

export default LoginInterceptor