import styled from "styled-components";

let titleBarHeight = 100;

export const OverviewBigScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #FFFFFF;
    letter-spacing: 2px;
    background-color: #2A2A2A;

    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 6px 120px 180px rgba(42, 42, 42), inset -48px -48px 150px rgba(42, 42, 42);
    }
`;

export const OverviewBigScreenInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 850px;
    color: #FFFFFF;
    letter-spacing: 2px;
`;

export const DeviceMapWrapper = styled.div`
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
`;

export const ScreenTitleBar = styled.div`
    position: absolute;
    z-index: 110;
    left: 0%;
    top: 0%;
    width: 100%;
    height: ${titleBarHeight-30}px;
    padding: 10px;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

export const DeviceOnlineStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: ${titleBarHeight}px;
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeviceListWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: calc((100% - ${titleBarHeight}px) * 1 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeviceTrafficStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    left: 0%;
    top: calc((100% - ${titleBarHeight}px) * 2 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeviceAlarmStatisticsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: ${titleBarHeight}px;
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeviceAlarmsWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: calc((100% - ${titleBarHeight}px) * 1 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeivceOnlineDurationWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: calc((100% - ${titleBarHeight}px) * 2 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;

export const DeviceModel3DWrapper = styled.div`
    position: absolute;
    z-index: 101;
    right: 0%;
    top: calc((100% - ${titleBarHeight}px) * 1 / 3 + ${titleBarHeight}px);
    width: 500px;
    height: calc((100% - ${titleBarHeight}px) / 3 - 20px);
    min-height: 250px;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(64, 64, 64, .2) inset;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
`;
