import { useEffect, useRef, useState } from "react";
import { DashboardWrapper, DeviceEventInterceptWrapper, DevicePopupMetricName, DevicePopupMetricValue, DevicePopupMetricWrapper, DevicePopupMetricsWrapper, DevicePopupName, DevicePopupSn, DevicePopupWrapper, DeviceSelectionWrapper, DevicesMapWrapper, LegendColor, LegendTitle, LegendValue, OverviewChartTitle, OverviewChartTitleText, OverviewChartWrapper, OverviewChartsWrapper, OverviewContent, OverviewEChartWrapper, OverviewLegend, OverviewLegendItem } from "./Dashboard.styled";
import { ECharts, init } from "echarts";
import { Amap, CircleMarker, InfoWindow } from "@amap/amap-react";
import Hammer from 'hammerjs';
import DashboardApi from "../../api/DashboardApi";
import DeviceApi from "../../api/DeviceApi";
import DeviceSelection from "../device-group/components/device-selection/DeviceSelection";
import DeviceMetricApi from "../../api/DeviceMetricApi";
import { isMobile } from "react-device-detect";
import { DeviceStatusUtils } from "../../components/ui-components/device-status/DeviceStatus";

interface DashboardProps {

}

const Dashboard: React.FC<DashboardProps> = (props)=> {

    // api
    let dashboardApi = new DashboardApi();
    let deviceApi = new DeviceApi();
    let deviceMetricApi = new DeviceMetricApi();

    // ref
    let selectedDeviceRef = useRef<any>();
    let interceptMapRef = useRef<any>();
    let mapRef = useRef<any>();
    let minMapZoom = useRef(3);

    // state
    let [systemOverview, setSystemOverview] = useState(null);
    let [deviceViews, setDeviceViews] = useState([]);
    let [selectedDevice, setSelectedDevice] = useState(null);
    let [selectedDeviceMetrics, setSelectedDeviceMetrics] = useState(null);
    let [mapCenter, setMapCenter] = useState([116, 39]);
    let [interceptMapEvent, setInterceptMapEvent] = useState(false);

    // overview charts
    let overviewCharts: any[] =[];
    if (systemOverview != null) {
        overviewCharts.push({
            title: '设备概况',
            data: [
                {name: '在线设备', value: systemOverview.deviceOverview.online, color: '#1CBC09'},
                {name: '离线设备', value: systemOverview.deviceOverview.offline, color: '#E83C2C'},
                {name: '设备总数', value: systemOverview.deviceOverview.total, color: '#101010', showOnChart: false},
            ]
        });
        overviewCharts.push({
            title: '告警事件',
            data: [
                {name: '未处理', value: systemOverview.alarmEventsOverview.unacked, color: '#E83C2C'},
                {name: '已处理', value: systemOverview.alarmEventsOverview.acked, color: '#1CBC09'},
                {name: '事件总数', value: systemOverview.alarmEventsOverview.total, showOnChart: false, color: '#999999'},
                {name: '今日', value: systemOverview.alarmEventsOverview.today, showOnChart: false, color: '#E83C2C'},
            ]
        });
        overviewCharts.push({
            title: '设备健康状态',
            data: [
                {name: '良好', value: systemOverview.deviceHealthOverview.good, color: '#1CBC09'},
                {name: '告警', value: systemOverview.deviceHealthOverview.warning, color: '#F8CA1D'},
                {name: '危险', value: systemOverview.deviceHealthOverview.dangerous, color: '#E83C2C'},
            ]
        });
    }

    // device map
    let deviceMarkers: any[] = [];
    for(let deviceView of deviceViews) {
        if (deviceView.latestHeartBeatMetric==null) {
            continue;
        }
        let mark = {
            key: deviceView.device.id,
            device: deviceView.device,
            node: deviceView.node,
            position: [deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude],
        };
        deviceMarkers.push(mark);
    }

    // events
    let fetchSelectedDeviceMetrics = async (deviceId: string)=> {
        let deviceMetrics = (await deviceMetricApi.getLatestDeviceMetricsView(deviceId)).data.data;
        setSelectedDeviceMetrics(deviceMetrics);
    };

    let onDeviceTreeSelected = (deviceId)=> {
        for(let deviceView of deviceViews) {
            if (deviceView.device.id == deviceId) {
                setSelectedDevice(deviceView);
                fetchSelectedDeviceMetrics(deviceId);
                setMapCenter([deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude])
                selectedDeviceRef.current = deviceView;
                break;
            }
        }
    };

    let onMarkerClick = (deviceId)=> {
        onDeviceTreeSelected(deviceId);
    };

    let initializeMapRef = (map)=> {
        mapRef.current = map;
        if (isMobile || !map) {
            return;
        }
        map.on('zoomchange', (e)=> {
            if (map.getZoom() < minMapZoom.current) {
                setInterceptMapEvent(true);
            }
        });
    };

    let initializeMapEventInterceptDom = (dom)=> {
        if (dom == null) {
            return;
        }

        let prevDistance = -1;
        dom.addEventListener('touchmove', function (event) {
            let touches = event.touches;
            let touch1 = touches[0];
            let touch2 = touches[1];
            if (touch1 && touch2) {
                let x = Math.abs(touch2.pageX - touch1.pageX);
                let y = Math.abs(touch2.pageY - touch1.pageY);
                let distance = Math.sqrt(x*x + y*y);
                if (prevDistance < 0) {
                    prevDistance = distance;
                } else {
                    event.preventDefault();
                    if (distance > prevDistance * 1.1) {
                        // zoom up
                        setInterceptMapEvent(false);
                        prevDistance = -1;
                        let map = mapRef.current;
                        if (map) {
                            if (map.getZoom() != minMapZoom.current) {
                                map.setZoom(minMapZoom.current);
                            }
                        }
                    }
                }
            } else {
                prevDistance = -1;
            }
        }, true);

        /*
        // let Hammer = (window as any).Hammer;
        var mc = new Hammer.Manager(dom);
        
        // create a pinch and rotate recognizer
        // these require 2 pointers
        var pinch = new Hammer.Pinch();

        // add to the Manager
        mc.add([pinch]);
        mc.on("pinch", function(ev) {
            if (ev.scale > 1) {
                setInterceptMapEvent(false);
                let map = mapRef.current;
                map.setZoom(minMapZoom.current);
            }
        });
        */
    }
    
    // onload
    useEffect(()=> {
        let loadSystemOverview = async ()=> {
            let systemRealtimeOverview = (await dashboardApi.getSystemRealtimeOverview()).data.data;
            setSystemOverview(systemRealtimeOverview);
        };

        let loadDeviceViews = async ()=> {
            let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
            setDeviceViews(deviceViews);
        }

        loadSystemOverview();
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadSystemOverview();
            loadDeviceViews();
            if (selectedDeviceRef.current!=null) {
                fetchSelectedDeviceMetrics(selectedDeviceRef.current.device.id);
            }
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    return (
        <DashboardWrapper>
            <OverviewChartsWrapper>
                {
                    overviewCharts.map((e)=> {
                        return <OverviewChart key={e.title} title={e.title} data={e.data} >
                        </OverviewChart>
                    })
                }
            </OverviewChartsWrapper>

            <DevicesMapWrapper>
                <Amap
                    mapStyle='amap://styles/dark'
                    zoom={4}
                    center={mapCenter}
                    ref={(map)=> initializeMapRef(map)}
                    >
                    {
                        deviceMarkers.map((e)=> {
                            return <CircleMarker
                                onClick={()=> { onMarkerClick(e.key); }}
                                key={e.key} 
                                center={e.position} 
                                radius={6} 
                                fillColor={DeviceStatusUtils.getDeviceMarkerColor(e.node.status)} 
                                fillOpacity={0.8} 
                                strokeWeight={0}
                                cursor="pointer" />
                        })
                    }

                    {
                        selectedDevice && selectedDeviceMetrics && (
                            <InfoWindow 
                                visible={selectedDevice.latestHeartBeatMetric!=null} 
                                position={[selectedDevice.latestHeartBeatMetric.longitude, selectedDevice.latestHeartBeatMetric.latitude]}
                                content={`
                                    <div>设备名称: ${selectedDevice.device?.name}</div>
                                    <div>序列号: ${selectedDevice.node?.sn}</div>
                                    <div>信号强度: ${selectedDevice.latestHeartBeatMetric?.rssi}dbm</div>
                                ` + Object.keys(selectedDeviceMetrics).map((e)=> {
                                    return `<div>
                                        <div>${e}: ${selectedDeviceMetrics[e]?.value}</div>
                                    </div>`
                                }).join('') }
                                onClose={()=> {
                                    setSelectedDevice(null);
                                    setSelectedDeviceMetrics(null);
                                }}
                                >

                                {
                                    selectedDeviceMetrics && (
                                        <DevicePopupWrapper>
                                            <DevicePopupName>{ selectedDevice.device?.name }</DevicePopupName>
                                            <DevicePopupSn>{ selectedDevice.node?.sn }</DevicePopupSn>
                                            <DevicePopupMetricsWrapper>
                                                {
                                                    Object.keys(selectedDeviceMetrics).map((key)=> {
                                                        return (
                                                            <DevicePopupMetricWrapper key={key}>
                                                                <DevicePopupMetricName>{selectedDeviceMetrics[key]}</DevicePopupMetricName>
                                                                <DevicePopupMetricValue>{selectedDeviceMetrics[key]}</DevicePopupMetricValue>
                                                            </DevicePopupMetricWrapper>
                                                        )
                                                    })
                                                }
                                            </DevicePopupMetricsWrapper>
                                        </DevicePopupWrapper>
                                    )
                                }
                            </InfoWindow>
                        )
                    }
                </Amap>
                
                <DeviceSelectionWrapper>
                    <DeviceSelection onSelect={onDeviceTreeSelected}/>
                </DeviceSelectionWrapper>

                { interceptMapEvent && <DeviceEventInterceptWrapper ref={(dom)=> initializeMapEventInterceptDom(dom)}/>}
            </DevicesMapWrapper>
        </DashboardWrapper>
    );
}

interface OverviewChartProps {
    title: string;
    data: OverviewChartLegendItem[];
}

interface OverviewChartLegendItem {
    name: string;
    value: number;
    color: string;
    showOnChart?: boolean;
}

const OverviewChart: React.FC<OverviewChartProps> = (props)=> {

    let chartData = props.data.filter((e)=> {
        return e.showOnChart!=false;
    });

    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);

    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echarts = init(dom);
        echartsRef.current = echarts;

        // init options
        let option = {
            tooltip: {
                show: false,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            color: chartData.map((e)=> {
                return e.color;
            }),
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['75%', '90%'],
                    avoidLabelOverlap: false,
                    hoverAnimation: true,
                    label: {
                        position: 'center',
                        formatter: (params)=> {
                            return `{symbol|: }{percent|${params.percent >= 100 ? params.percent.toFixed(0) : params.percent.toFixed(1)}}{symbol| %}\n{title|${params.name}}`
                        },
                        rich: {
                            percent: {
                                padding: [8, 0, 0, 0],
                                color: '#101010',
                                fontSize: 32,
                            },
                            symbol: {
                                color: '#101010',
                                fontSize: 11,
                            },
                            title: {
                                color: '#333333',
                                fontSize: 14,
                                padding: [5, 0, 0, 0],

                            }
                        }
                    },
                    emphasis: {
                        disabled: false,
                        label: {
                            formatter: (params)=> {
                                return `{symbol|: }{percent|${params.percent >= 100 ? params.percent.toFixed(0) : params.percent.toFixed(1)}}{symbol| %}\n{title|${params.name}}`
                            },
                            rich: {
                                percent: {
                                    padding: [8, 0, 0, 0],
                                    color: '#101010',
                                    fontSize: 32,
                                },
                                symbol: {
                                    color: '#101010',
                                    fontSize: 11,
                                },
                                title: {
                                    color: '#333333',
                                    fontSize: 14,
                                    padding: [5, 0, 0, 0],
    
                                }
                            }
                        },
                    },
                    labelLine: {
                        show: true
                    },
                    data: chartData,
                }
            ]
        };
        echarts.setOption(option);

        // set default highlighted legend
        let onMouseOut = ()=> {
            echarts.setOption({
                emphasis: {
                    label: { show: false }
                },
                series: [{
                    label: { show: true },
                }]
            });
        }
        let onMouseOver = ()=> {
            echarts.setOption({
                emphasis: {
                    label: { show: true }
                },
                series: [{
                    label: { show: false },
                }]
            });
        }
        echarts.on('mouseout', (e)=> {
            onMouseOut();
        });
        echarts.on('mouseover', (e)=> {
            onMouseOver();
        });
    }

    useEffect(()=> {
        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            if(echartsRef.current!=null) {
                echartsRef.current.dispose();
            }
        }
    }, [])

    return (
    <OverviewChartWrapper>
        <OverviewChartTitle>
            <OverviewChartTitleText>{props.title}</OverviewChartTitleText>
        </OverviewChartTitle>
        <OverviewContent>
            <OverviewEChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>

            </OverviewEChartWrapper>

            <OverviewLegend>
            {
                props.data.map(e=> {
                    return <OverviewLegendItem key={e.name}>
                            <LegendColor style={{backgroundColor: e.color}}></LegendColor>
                            <LegendTitle>{e.name}</LegendTitle>
                            <LegendValue>{e.value}</LegendValue>
                        </OverviewLegendItem>
                })
            }
            </OverviewLegend>

        </OverviewContent>
    </OverviewChartWrapper>
    );
}

export default Dashboard;
