
class SeriesColorUtils {
    public static getSeriesColor(index: number) {
        let colors = [
            '#C23531',
            '#7728F5',
            '#6F83A5',
            '#6482E6',
            '#81B337',
            '#E99D42',
            '#A5C2D5',
            '#BD3124',
        ];
        
        return colors[index % colors.length];
    }
}

export default SeriesColorUtils;
