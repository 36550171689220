import { AxiosResponse } from "axios";
import AxiosService from "./Axios";

const PZT_DATA_FILE_URL_PREFIX = "/pzt/data-file";

class PztDataFileApi {

    public async getDevicePztDataFileViews(deviceId: string):  Promise<AxiosResponse<any>> {
        return AxiosService.get(`${PZT_DATA_FILE_URL_PREFIX}/views/device?deviceId=${deviceId}`);
    }
}

export default PztDataFileApi;
