import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/product";

class ProductApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getProductByModel(model: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/model/' + model);
    }
}

export default ProductApi;
