import styled from "styled-components";

export const DeviceOnlineStatisticsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
`;

export const StatisticsOverview = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const StatisticsItem = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StatisticsItemLabel = styled.div`
    padding: 5px 0px;
`;

export const StatisticsItemValue = styled.div`
    font-size: 24px;
`;

export const EChartWrapper = styled.div`
    flex: 1 1 auto;
`;
