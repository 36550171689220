import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"

import Navigator from "../common/navigator/Navigator";
import { DetailsDetailsAndMapWrapper, DeviceDetailsContent, DeviceDetailsScreenInnerWrapper, DeviceDetailsScreenWrapper, DeviceDetailsWrapper, DeviceModel3DAndHealthWrapper, DeviceModel3DWrapper, DeviceTrackWrapper, ScreenTitleBar } from "./DeviceDetailsScreen.styled";
import DeviceModel3D from "./components/device-model-3d/DeviceModel3D";
import DeviceTrack from "./components/device-track/DeviceTrack";
import DeviceDetails from "./components/device-details/DeviceDetails";
import DeviceMetrics from "./components/device-metrics/DeviceMetrics";
import DeviceHealth from "./components/device-health/DeviceHealth";
import DeviceRealtimeMetrics from "./components/device-realtime-metrics/DeviceRealtimeMetrics";
import ContentCard from "./common/content-card/ContentCard";
import { ISourceOptions, MoveDirection, OutMode } from "@tsparticles/engine";

let globalParticlesEngineInited = false;

interface DeviceDetailsScreenProps {

}

let DeviceDetailsScreen: React.FC<DeviceDetailsScreenProps> = (props)=> {
    // parameters
    let params = useParams();
    let { deviceId } = useParams();

    // state
    let [particlesEngineInited, setParticlesEnineInited] = useState(globalParticlesEngineInited);

    // function
    let initializeScreenWrapper = (dom)=> {

    };

    // particles options
    const options: ISourceOptions = useMemo(
        () => ({
            background: {
                color: {
                    value: "transparent",
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#0f62fe",
                },
                links: {
                    color: "#0f62fe",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: MoveDirection.none,
                    enable: true,
                    outModes: {
                        default: OutMode.out,
                    },
                    random: false,
                    speed: 1,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 3 },
                },
            },
            detectRetina: true,
        }),
        [],
    );
    
    // particles typescript
    useEffect(() => {
        if (particlesEngineInited && globalParticlesEngineInited) {
            return;
        }

        initParticlesEngine(async (engine) => {
        // await loadAll(engine);
        // await aloadFull(engine);
        await loadSlim(engine);
        // await loadBasic(engine);
        }).then(() => {
            globalParticlesEngineInited = true;
            setParticlesEnineInited(true);
        });
    }, []);

    // render
    return (
        <DeviceDetailsScreenWrapper ref={(dom)=> initializeScreenWrapper(dom)}>
            {particlesEngineInited && <Particles
                id="tsparticles"
                options={options}
            />}
            <DeviceDetailsScreenInnerWrapper>
                <ScreenTitleBar>
                    <Navigator selected="DEVICE_DETAILS"/>
                </ScreenTitleBar>

                <DeviceDetailsContent>
                    <ContentCard 
                        style={{
                            gridColumn: '1 / 2',
                            gridRow: '1 / 2',
                        }}
                        titleCn="设备信息" 
                        titleEn="DEVICE INFORMATION">

                        <DetailsDetailsAndMapWrapper>
                            <DeviceDetailsWrapper>
                                <DeviceDetails deviceId={deviceId} />
                            </DeviceDetailsWrapper>
                            <DeviceTrackWrapper>
                                <DeviceTrack deviceId={deviceId} />
                            </DeviceTrackWrapper>
                        </DetailsDetailsAndMapWrapper>
                    </ContentCard>

                    <DeviceModel3DAndHealthWrapper>
                        <DeviceModel3DWrapper>
                            <DeviceModel3D />
                        </DeviceModel3DWrapper>

                        <ContentCard 
                            titleCn="实时数据" 
                            titleEn="REALTIME METRICS">
                            <DeviceRealtimeMetrics deviceId={deviceId}/>
                        </ContentCard>
                    </DeviceModel3DAndHealthWrapper>

                    <ContentCard 
                        style={{
                            gridColumn: '1 / 2',
                            gridRow: '2 / 3',
                        }}
                        titleCn="健康状态" 
                        titleEn="HEALTH STATUS">
                        <DeviceHealth deviceId={deviceId} />
                    </ContentCard>

                    <ContentCard 
                        style={{
                            gridColumn: '2 / 4',
                            gridRow: '2 / 3',
                        }}
                        titleCn="数据曲线" 
                        titleEn="METRICS CHARTS">
                        <DeviceMetrics deviceId={deviceId} />
                    </ContentCard>
                </DeviceDetailsContent>
            </DeviceDetailsScreenInnerWrapper>

        </DeviceDetailsScreenWrapper>
    )
};

export default DeviceDetailsScreen;

