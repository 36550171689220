import { useEffect, useState } from "react";
import { Content, EventTime, Title } from "./DeviceAlarms.styled";
import { AlarmItem } from "./DeviceAlarms.styled";
import { DeviceAlarmsWrapper } from "./DeviceAlarms.styled";
import { DeviceName } from "./DeviceAlarms.styled";
import { AlarmMessage } from "./DeviceAlarms.styled";
import AlarmEventApi from "../../../../../api/AlarmEventApi";
import moment from "moment";

interface DeviceAlarmsProprs {

}

export const DeviceAlarms: React.FC<DeviceAlarmsProprs> = (props)=> {
    // api
    let alarmEventApi = new AlarmEventApi();

    // render
    let [alarmEvents, setAlarmEvents] = useState([]);

    // onload
    useEffect(()=> {
        let loadAlarmEventViews = async ()=> {
            let deviceViews = (await alarmEventApi.queryViews({
                pageNo: 1,
                pageSize: 50,
            })).data.data.elements;
            setAlarmEvents(deviceViews);
        }
        loadAlarmEventViews();

        let refreshTask = setInterval(()=> {
            loadAlarmEventViews();
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);
    
    // function
    let formatDateTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    }

    // render
    return (
        <DeviceAlarmsWrapper>
            <Title>设备告警列表</Title>
            <Content>
                {
                    alarmEvents.map((e)=> {
                        return <AlarmItem key={e.event.id}>
                            <DeviceName>{e.device.name}</DeviceName>
                            <EventTime>{formatDateTime(parseInt(e.event.eventTime || e.event.startTime))}</EventTime>
                            <AlarmMessage>{e.event.details}</AlarmMessage>
                        </AlarmItem>
                    })
                }
            </Content>
        </DeviceAlarmsWrapper>
    )
};
