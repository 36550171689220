import { useEffect, useRef, useState } from "react";
import { Content, DeviceOnlineStatisticsWrapper, EChartWrapper, StatisticsItem, StatisticsItemLabel, StatisticsItemValue, StatisticsOverview, Title } from "./DeviceOnlineStatistics.styled";
import DashboardApi from "../../../../../api/DashboardApi";
import StringUtils from "../../../../../util/StringUtils";
import { ECharts } from "echarts";
import * as echarts from "echarts";


interface DeviceOnlineStatisticsProprs {

}

export const DeviceOnlineStatistics: React.FC<DeviceOnlineStatisticsProprs> = (props)=> {
    let dashboardApi = new DashboardApi();

    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);
        
    // state
    let [systemOverview, setSystemOverview] = useState(null);
    
    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: true,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            grid: {
                x: 70,
                y: 10,
                x2: 50,
                y2: 10,
            },
            xAxis: {
                type: 'value',
                data: [],
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                splitLine:{
                    show:false // 不显示网格线
                },
            },
            yAxis: {
                type: 'category',
                show: true,
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: '#FFFFFF',
                }
            },
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: [],
                    showBackground: true,
                    backgroundStyle: {
                      color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            ]
        };
        echartsInstance.setOption(option);
    }

    // onload
    useEffect(()=> {
        let loadSystemOverview = async ()=> {
            let systemRealtimeOverview = (await dashboardApi.getSystemRealtimeOverview()).data.data;
            setSystemOverview(systemRealtimeOverview);
            
            // refresh echarts options
            echartsRef.current?.setOption({
                yAxis: {
                    data: ['设备总数', '在线数', '离线数', '告警数'].reverse()
                },
                series: [
                    {data: [
                        {value: systemRealtimeOverview.deviceOverview.total, itemStyle: {color: '#546FC6'}},
                        {value: systemRealtimeOverview.deviceOverview.online, itemStyle: {color: '#1EA77B'}},
                        {value: systemRealtimeOverview.deviceOverview.offline, itemStyle: {color: '#F97D5B'}},
                        {value: systemRealtimeOverview.deviceOverview.warning ?? 0, itemStyle: {color: '#E70302'}},
                    ].reverse()}
                ]
            });

        };
        loadSystemOverview();

        let refreshTask = setInterval(()=> {
            loadSystemOverview();
        }, 5000);

        
        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            clearInterval(refreshTask);
            if (echartsRef.current) {
                echartsRef.current.dispose();
            }
        }
    }, []);
    
    // render
    return (
        <DeviceOnlineStatisticsWrapper>
            <Title>设备概况</Title>
            <Content>
                <StatisticsOverview>
                    <StatisticsItem>
                        <StatisticsItemLabel>设备总数</StatisticsItemLabel>
                        <StatisticsItemValue style={{color: '#546FC6'}}>{StringUtils.emptyTo0(systemOverview?.deviceOverview?.total)}</StatisticsItemValue>
                    </StatisticsItem>
                    <StatisticsItem>
                        <StatisticsItemLabel>在线设备</StatisticsItemLabel>
                        <StatisticsItemValue style={{color: '#1EA77B'}}>{StringUtils.emptyTo0(systemOverview?.deviceOverview?.online)}</StatisticsItemValue>
                    </StatisticsItem>
                    <StatisticsItem>
                        <StatisticsItemLabel>离线设备</StatisticsItemLabel>
                        <StatisticsItemValue style={{color: '#F97D5B'}}>{StringUtils.emptyTo0(systemOverview?.deviceOverview?.offline)}</StatisticsItemValue>
                    </StatisticsItem>
                    <StatisticsItem>
                        <StatisticsItemLabel>告警设备</StatisticsItemLabel>
                        <StatisticsItemValue style={{color: '#E70302'}}>{StringUtils.emptyTo0(systemOverview?.deviceOverview?.warning)}</StatisticsItemValue>
                    </StatisticsItem>
                </StatisticsOverview>

                <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                </EChartWrapper>
            </Content>
        </DeviceOnlineStatisticsWrapper>
    )
};

