
class FirmwarePushTaskUtils {
    private static taskStatusMetas = [
        {ordinal: 0, name: 'INITIALIZING', text: '任务初始化'},
        {ordinal: 1, name: 'WAITING_NODE_OTA', text: '等待设备固件设备'},
        {ordinal: 2, name: 'DONE', text: '任务完成'},
        {ordinal: 3, name: 'DONE_WITH_ERROR', text: '任务完成（有失败设备）'},
        {ordinal: 4, name: 'CANCELLED', text: '任务取消'},
        {ordinal: 5, name: 'TIMEOUT', text: '任务超时'},
    ];

    private static nodeOtaStatusMetas = [
        {ordinal: 0, name: 'PENDING', text: '等待OTA'},
        {ordinal: 1, name: 'RECEIVED_OTA_MESSAGE', text: '已接收OTA消息'},
        {ordinal: 2, name: 'DOWNLOADING_FIRMWARE', text: '下载固件中'},
        {ordinal: 3, name: 'REBOOT', text: '正在重启'},
        {ordinal: 4, name: 'DONE', text: '完成'},
        {ordinal: 5, name: 'ERROR', text: '错误'},
    ];

    public static taskStatusName2Text(taskStatus: string) {
        for(let taskStatusMeta of this.taskStatusMetas) {
            if(taskStatusMeta.name == taskStatus) {
                return taskStatusMeta.text;
            }
        }
        
        return '';
    }

    public static taskStatusName2Ordinal(taskStatus: string) {
        for(let taskStatusMeta of this.taskStatusMetas) {
            if(taskStatusMeta.name == taskStatus) {
                return taskStatusMeta.ordinal;
            }
        }
        
        return -1;
    }

    public static nodeOtaStatus2Text(nodeOtaStatus: string) {
        for(let nodeOtaStatusMeta of this.nodeOtaStatusMetas) {
            if(nodeOtaStatusMeta.name == nodeOtaStatus) {
                return nodeOtaStatusMeta.text;
            }
        }
        
        return '';
    }
}

export default FirmwarePushTaskUtils;
