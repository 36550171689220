
import { Tabs } from "antd";
import { DeviceTemplateNameTitle, GoBackText, GoBackWrapper, TitleBar } from "./DeviceTemplateDetails.styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewStateService from "../../../../services/view-state/ViewStateService";
import DeviceTemplateApi from "../../../../api/DeviceTemplateApi";
import DeviceTemplateInformation from "./components/device-template-information/DeviceTemplateInformation";
import DeviceDefaultSettings from "./components/device-default-settings/DeviceDefaultSettings";
import DeviceVariables from "./components/device-variables/DeviceVariables";
import { DeviceDetailsWrapper } from "../../../device-management/components/device-details/DeviceDetails.styled";

interface DeviceTemplateDetailsProps {
    onClose: ()=> void,
    onComplete: ()=> void
}

const DeviceTemplateDetails: React.FC<DeviceTemplateDetailsProps> = (props)=> {
    // api
    let navigate = useNavigate();
    let location = useLocation();
    let viewStateService = ViewStateService.getInstance();
    let deviceTemplateApi = new DeviceTemplateApi();

    // parameters
    let {deviceTemplateId} = useParams();

    // state
    let [deviceTemplateView, setDeviceTemplateView] = useState(null);

    // events
    let loadDeviceTemplate = async ()=> {
        if(!deviceTemplateId) {
            return;
        }
        let deviceTemplateView = (await deviceTemplateApi.getDeviceTemplateViewByDeviceId(deviceTemplateId)).data.data;
        setDeviceTemplateView(deviceTemplateView);
    }

    let onGoBack = ()=> {
        if(location.key=='default') {
            navigate("/device-template-management");
        } else {
            navigate(-1);
        }
    }
    
    useEffect(()=> {
        // set status bar title
        viewStateService.setStatusBarTitle('设备模板详情');

        // load device information according to device id parameter
        loadDeviceTemplate();

    }, []);
    
    // render
    let tabItems = [
        {
            key: 'device-template-information',
            label: '模板信息',
            children: deviceTemplateView && <DeviceTemplateInformation deviceTemplateView={deviceTemplateView} />
        },
        {
            key: 'device-default-settings',
            label: '设备默认设置',
            children: deviceTemplateView && <DeviceDefaultSettings deviceTemplateView={deviceTemplateView} />
        },
        {
            key: 'device-variables',
            label: '设备变量',
            children: deviceTemplateView && <DeviceVariables deviceTemplateView={deviceTemplateView} />
        },
    ];

    return (
        <DeviceDetailsWrapper>
            <TitleBar>
                <GoBackWrapper onClick={onGoBack}>
                    <LeftCircleFilled style={{fontSize: '20px'}} />
                    <GoBackText>返回</GoBackText>
                </GoBackWrapper>
                <DeviceTemplateNameTitle>{deviceTemplateView?.deviceTemplate?.name}</DeviceTemplateNameTitle>
            </TitleBar>
    
            { deviceTemplateView && 
                <Tabs
                    defaultActiveKey="device-information"
                    items={tabItems}
                    style={{flex: '1 1 auto', overflow: 'hidden'}}
                />
            }
        </DeviceDetailsWrapper>
    )
}

export default DeviceTemplateDetails;

