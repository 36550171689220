import { KeyboardEvent, useState } from "react";
import LoginApi from "../../api/LoginApi";
import Api from "../../api/Api";
import SessionService from "../../services/session/SessionService";
import { ErrorMessage, FormItemInput, FormItemLabel, FormItemWrapper, FormSubmit, LoginDialogWrapper, LoginForm, LoginFormDescription, LoginFormTitle, LoginFormTitleEn, LoginFormWrapper, LoginPicture, LoginWrapper } from "./Login.styled";

interface LoginProps {
}

const Login: React.FC<LoginProps> = (props)=> {
    // state
    let [userName, setUserName] = useState('');
    let [password, setPassword] = useState('');
    let [error, setError] = useState('');

    // events
    let onUserNameChange = (value: string)=> {
        setUserName(value);
    };

    let onPasswordChange = (value: string)=> {
        setPassword(value);
    }

    let onPasswordKeyDown = (e: KeyboardEvent)=> {
        if (e.key.toLowerCase() == 'Enter'.toLowerCase()) {
            login();
        }
    }

    let login = async ()=> {
        let loginApi = new LoginApi();

        let isEmail = userName.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');
        let isPhone = userName.match('^1[345789]\d{9}$');

        let signInData: any = {password: password};
        if (isEmail) {
            signInData.email = userName;
        } else if(isPhone) {
            signInData.phoneNumber = userName;
        } else {
            signInData.name = userName;
        }

        let loginResponse = await loginApi.signIn(signInData);
        if (loginResponse.data.code != Api.CODE_OK) {
            setError("用户名/密码不正确");
        } else {
            let sessionService = SessionService.getInstance();
            sessionService.setAuthorization(loginResponse.data.data.token);
            sessionService.fireSessionChangeListeners();
        }
    };

    function setScale(wrapperDom: HTMLElement) {
        if (wrapperDom == null) {
            return;
        }
        let screenWidth = window.screen.width;
        let screenHeight = window.screen.height;

        let widthRatio = screenWidth / 1920;
        let heightRatio = screenHeight / 1080;
        let ratio = widthRatio > heightRatio ? widthRatio : heightRatio;
        let rootFontSize = 12 * ratio;
        document.documentElement.style.fontSize = `${rootFontSize}px`;
        document.body.style.fontSize = `${rootFontSize}px`;
        // wrapperDom.style.transform = `scale(${ratio}) translate(-50%,-50%)`;
    }

    // render
    return (
        <LoginWrapper ref={(dom)=> setScale(dom)}>
            <LoginDialogWrapper>
                <LoginFormWrapper>
                    <LoginForm>
                        <LoginFormTitle>复材健康数据管理平台</LoginFormTitle>
                        <LoginFormTitleEn>CFRP Health Management</LoginFormTitleEn>
                        <LoginFormDescription>复材健康数据监测与管理平台，在这里您可以轻松追踪您设备的实时状态，获取最新的数据，以及进行各种智能分析。</LoginFormDescription>
                        
                        <FormItemWrapper>
                            <FormItemLabel>用户名/手机号/邮箱</FormItemLabel>
                            <FormItemInput placeholder="请输入用户名/手机号/邮箱" value={userName} onChange={(e)=> onUserNameChange(e.target.value)}></FormItemInput>
                        </FormItemWrapper>
                        <FormItemWrapper>
                            <FormItemLabel>密码</FormItemLabel>
                            <FormItemInput placeholder="请输入登录密码" type='password' style={{letterSpacing: '3px'}} value={password} onChange={(e)=> onPasswordChange(e.target.value)} onKeyDown={(e)=> onPasswordKeyDown(e)}></FormItemInput>
                        </FormItemWrapper>

                        <ErrorMessage>{error}</ErrorMessage>
                        
                        <FormSubmit onClick={login}>
                            登录
                        </FormSubmit>
                    </LoginForm>
                </LoginFormWrapper>
            </LoginDialogWrapper>
        </LoginWrapper>
    );
}

export default Login;

