import React, { useEffect, useState } from "react";
import WeatherApi from "../../../../../api/WeatherApi";
import StringUtils from "../../../../../util/StringUtils";
import { City, LocationIcon, Temperature, TemperatureUnit, WeatherIcon, WeatherWrapper } from "./Weather.styled";

interface WeatherProps {

}


export const Weather: React.FC<WeatherProps> = (props)=> {

    // api
    let weatherApi = new WeatherApi();

    // state
    let [weather, setWeather] = useState({} as any);

    let loadWeather = async ()=> {
        let weather = (await weatherApi.query()).data.data;
        setWeather(weather);
    };
    // effect
    useEffect(()=> {
        loadWeather();
    }, []);

    let getWeatherIcon = ()=> {
        if (weather == null) {
            return null;
        }
        let mappings = [
            { tags: '阴', icon: '#icon-a-Overcastsky'},
            { tags: '晴', icon: '#icon-sunny-light'}, 
            { tags: '晴,夜', icon: '#icon-sunny-night'},
            { tags: '多云', icon: '#icon-cloudy-light'}, 
            { tags: '晴,多云', icon: '#icon-cloudy-light'}, 
            { tags: '晴,多云,夜', icon: '#icon-cloudy-night'}, 
            { tags: '中雨', icon: '#icon-a-moderaterain'}, 
            { tags: '雷,雨', icon: '#icon-rainstorm'}, 
            { tags: '晴,阵雨', icon: '#icon-shower-light'}, 
            { tags: '小雨', icon: '#icon-a-smallrain'}, 
            { tags: '阵雨,夜', icon: '#icon-shower-night'}, 
            { tags: '大雨', icon: '#icon-a-heavyrain'}, 
            { tags: '小雪', icon: '#icon-a-Lightsnow'}, 
            { tags: '雷,暴雨', icon: '#icon-thundershower'}, 
            { tags: '雨,雪', icon: '#icon-sleet'}, 
            { tags: '中雪', icon: '#icon-a-Moderatesnow'}, 
            { tags: '大雪', icon: '#icon-a-Heavysnow'}, 
            { tags: '暴风,雪', icon: '#icon-blizzard'}, 
            { tags: '雹', icon: '#icon-hailstone'}, 
            { tags: '薄雾', icon: '#icon-haze'}, 
            { tags: '沙尘', icon: '#icon-a-Sandblowing'}, 
            { tags: '雾', icon: '#icon-a-Thefog'}, 
            { tags: '雪', icon: '#icon-snow'},
        ];

        let bestMatchMapping = null as any;
        let bestMatchTagCount = 0;
        let text = StringUtils.nonull(weather.weather) + (new Date().getHours() <= 19 ? '' : '夜');
        for(let mapping of mappings) {
            let tags = mapping.tags.split(',');
            
            let matchTagCount = 0;
            let match = true;
            for(let tag of tags) {
                if (text.indexOf(tag) < 0) {
                    // not matched
                    match = false;
                } else {
                    matchTagCount++;
                }
            }

            if (!match) {
                continue;
            }
            if (matchTagCount >= bestMatchTagCount) {
                bestMatchMapping = mapping;
                bestMatchTagCount = matchTagCount;
            }
        }
        if (bestMatchMapping != null) {
            return <svg className="icon" aria-hidden="true">
                <use xlinkHref={bestMatchMapping.icon}></use>
            </svg>
        } else {
            return <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-sunny-light"></use>
            </svg>
        }
    };

    return <WeatherWrapper>
        <LocationIcon className="iconfont icon-location"></LocationIcon>
        <City>{weather.city}</City>
        <WeatherIcon>{getWeatherIcon()}</WeatherIcon>
        <Temperature>{StringUtils.emptyToSlash(weather.temperature)}­<TemperatureUnit>°C</TemperatureUnit></Temperature>
    </WeatherWrapper>
};

