import { Button, Form, Input, Select, Space, Upload, message } from "antd";
import FirmwareApi from "../../../../api/FirmwareApi";
import { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { CreateFirmwareWizardWrapper, FormWrapper, OperationWrapper, Title, TitleWrapper, UploadHints, UploadIconWrapper, UploadText } from "./CreateFirmwareWizard.styled";
import ProductApi from "../../../../api/ProductApi";


interface CreateFirmwareWizardProps {
    onClose: ()=> void,
    onComplete: ()=> void
}

const CreateFirmwareWizard: React.FC<CreateFirmwareWizardProps> = (props)=> {
    // api
    let productApi = new ProductApi();
    let firmwareApi = new FirmwareApi();
    const [messageApi, contextHolder] = message.useMessage();

    // form
    const [form] = Form.useForm();

    // functions
    let loadProductModels = async ()=> {
        let products = (await productApi.getAll()).data.data;
        let productModelOptions = products.map(e=> {
            return {
                label: e.model,
                value: e.model
            }
        });
        setProductModelOptions(productModelOptions);
    };

    // state
    let [submitEnabled, setSubmitEnabled] = useState(false);
    let [productModelOptions, setProductModelOptions] = useState([]);
    let [firmwareParameters, setFirmwareParameters] = useState({} as any);

    // event
    let onProductModelChange = (selectedValue: string)=> {
        firmwareParameters.productModel = selectedValue;
        validateForm();
    };

    let onVersionChange = (e)=> {
        firmwareParameters.version = e.target.value;
        validateForm();
    };

    let onRemarksChange = (e)=> {
        firmwareParameters.remarks = e.target.value;
        validateForm();
    };

    let onFileChange = (e)=> {
        let file = e.file.originFileObj || e.file;
        let reader=new FileReader();
        reader.onload=(e)=>{
            // complete callback
            let target: any=e.target;
            let base64=target.result;
            let base64Data=base64.substring(base64.indexOf('base64,') + 'base64,'.length);
            firmwareParameters.data=base64Data;
            validateForm();
        };
        
        reader.readAsDataURL(file);
    };

    let onSubmit = async ()=> {
        await firmwareApi.create(firmwareParameters);
        props.onComplete();
        props.onClose();
    };

    let onCancel = ()=> {
        props.onClose();
    };

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    }

    let versionValidator = (rule, value, callback)=> {
        let valid = true;
        if (value) {
            let versionRegexp = new RegExp("^\\d+\\.\\d+\\.\\d+$");
            if(!versionRegexp.test(value)) {
                valid = false;
            }
        }
        if (!valid) {
            callback("版本号不合法");
        } else {
            callback();
        }
    }
    
    // effect
    useEffect(()=> {
        loadProductModels();
    }, []);

    // render
    return (
        <CreateFirmwareWizardWrapper>
            <TitleWrapper>
                <Title>新增固件</Title>
            </TitleWrapper>

            <FormWrapper>
                <Form form={form} name="validateOnly" layout="vertical">
                    <Form.Item name="productModel" label="产品型号" rules={[{required: true}]}>
                        <Select
                            placeholder="请选择产品型号"
                            value={firmwareParameters.productModel}
                            onChange={onProductModelChange}
                            options={productModelOptions}
                        />
                    </Form.Item>

                    <Form.Item name="version" label="版本号" rules={[{required: true}, {validator: versionValidator}]}>
                        <Input value={firmwareParameters.version} onChange={onVersionChange} placeholder="请输入版本号，如 1.2.1" />
                    </Form.Item>

                    <Form.Item name="file" label="固件文件" rules={[{required: true}]}>
                        <Upload.Dragger name="file" maxCount={1} multiple={false} onChange={onFileChange} beforeUpload={()=> false}>
                            <UploadIconWrapper><InboxOutlined /></UploadIconWrapper>
                            <UploadText>点击或者拖动文件到此区域</UploadText>
                            <UploadHints>注意：请选择编译工具生成的扩展名为.bin的固件文件，错误的固件将导致设备无法启动！</UploadHints>
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item name="remarks" label="备注" rules={[{required: false}]}>
                        <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange} placeholder="请输入固件备注"/>
                    </Form.Item>
                </Form>
            </FormWrapper>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!submitEnabled} onClick={onSubmit}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </CreateFirmwareWizardWrapper>
    )
}

export default CreateFirmwareWizard;
