import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class DashboardApi {
    
    public async getSystemRealtimeOverview(): Promise<AxiosResponse<any>> {
        return AxiosService.get('/dashboard/system-realtime-overview');
    }

}

export default DashboardApi;