import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/firmware-push-task";

class FirmwarePushTaskApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async pushFirmware(request: {
        firmwareId: string;
        nodeIds: string[];
    }): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/push-firmware', request);
    }

    public async queryTasks(request: {
        status?: string,
        nodeId?: string,
        startTime?: number,
        endTime?: number,
        pageNo: number,
        pageSize: number,
    }): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/view/query', request);
    }

    public async getTaskViewById(request: {
        id: string,
    }): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/view/get', {
            params: request
        });
    }
}

export default FirmwarePushTaskApi;
