interface RoleManagementProps {

}

const RoleManagement: React.FC<RoleManagementProps> = (props)=> {
    // render
    return (
        <div>User Management, Developing...</div>
    );
}

export default RoleManagement;
