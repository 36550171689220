import { useEffect, useRef, useState } from "react";
import { Content, EChartWrapper, HistoricalAlarmsChart, OverviewItemLabel, OverviewItemValue, Title, TodayAlarmsOverview, TodayProcessed, TodayTotal, TodayUnprocessed } from "./DeviceAlarmStatistics.styled";
import { DeviceAlarmsWrapper } from "./DeviceAlarmStatistics.styled";
import AlarmEventApi from "../../../../../api/AlarmEventApi";
import { ECharts } from "echarts";
import * as echarts from "echarts";

interface DeviceAlarmStatisticsProprs {

}

export const DeviceAlarmStatistics: React.FC<DeviceAlarmStatisticsProprs> = (props)=> {
    // api
    let alarmEventApi = new AlarmEventApi();

    // ref
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);
    
    // state
    let [alarmEventStatistics, setAlarmEventStatistics] = useState({} as any);

    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: true,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            grid: {
                x: 10,
                y: 20,
                x2: 10,
                y2: 40,
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    show: false,
                }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: [],
                    color: '#E70302',
                    showBackground: true,
                    backgroundStyle: {
                      color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            ]
        };
        echartsInstance.setOption(option);
    }

    // onload
    useEffect(()=> {
        let loadAlarmEventStatistics = async ()=> {
            let alarmEventStatistics = (await alarmEventApi.getStatistics()).data.data;
            setAlarmEventStatistics(alarmEventStatistics);

            // refresh echarts options
            echartsRef.current?.setOption({
                xAxis: [
                    {data: alarmEventStatistics.historical.map(e=> e.date)}
                ],
                series: [
                    {data: alarmEventStatistics.historical.map(e=> e.value)}
                ]
            });
        }
        loadAlarmEventStatistics();

        let refreshTask = setInterval(()=> {
            loadAlarmEventStatistics();
        }, 5000);

        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            clearInterval(refreshTask);
            if(echartsRef.current!=null) {
                echartsRef.current.dispose();
            }
        }
    }, []);

    // render
    return (
        <DeviceAlarmsWrapper>
            <Title>告警统计</Title>
            <Content>
                <TodayAlarmsOverview>
                    <TodayTotal>
                        <OverviewItemLabel>事件总数</OverviewItemLabel>
                        <OverviewItemValue style={{color: '#F97D5B'}}>{alarmEventStatistics?.today?.total}</OverviewItemValue>
                    </TodayTotal>
                    <TodayUnprocessed>
                        <OverviewItemLabel>未处理</OverviewItemLabel>
                        <OverviewItemValue style={{color: '#E70302'}}>{alarmEventStatistics?.today?.unprocessed}</OverviewItemValue>
                    </TodayUnprocessed>
                    <TodayProcessed>
                        <OverviewItemLabel>已处理</OverviewItemLabel>
                        <OverviewItemValue style={{color: '#1EA77B'}}>{alarmEventStatistics?.today?.processed}</OverviewItemValue>
                    </TodayProcessed>
                </TodayAlarmsOverview>

                <HistoricalAlarmsChart>
                    <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                    </EChartWrapper>
                </HistoricalAlarmsChart>
            </Content>
        </DeviceAlarmsWrapper>
    )
};
