import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class OnlineOfflineEventApi {
    
    public async queryViews(request: QueryOnlineOfflineEventViewsRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post('/online-offline/view/query', request);
    }
}


interface QueryOnlineOfflineEventViewsRequest {
    deviceId?: string,
    pageNo: number,
    pageSize: number,
    startTime?: number,
    endTime?: number,
}

export default OnlineOfflineEventApi;