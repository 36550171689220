
import { Button, Form, Input, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { ColumnType } from "antd/es/table";
import { ChooseNodeStepWrapper, NodeListWrapper, NodeListItem, NodeMapWrapper, NodeProductModel, NodeSn, OperationWrapper, ProgressContent, ProgressStatusWrapper, StepTitle, CreateDeviceWizardWrapper } from "./CreateDeviceWizard.styled";
import StringUtils from "../../../../util/StringUtils";
import DeviceTemplateApi from "../../../../api/DeviceTemplateApi";
import { Amap, CircleMarker, InfoWindow } from "@amap/amap-react";
import NodeApi from "../../../../api/NodeApi";
import DeviceApi from "../../../../api/DeviceApi";

interface CreateDeviceWizardProps {
    onClose: ()=> void,
    onComplete: ()=> void
}

const CreateDeviceWizard: React.FC<CreateDeviceWizardProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();
    const [messageApi, contextHolder] = message.useMessage();

    // state
    let [step, setStep] = useState(0);
    let [createDeviceParameters, setCreateDeviceParameters] = useState(new CreateDeviceParameters());
    let [canNext, setCanNext] = useState(false);

    let onNext = ()=> {
        if (step < 2) {
            setStep(step+1);
            setCanNext(false);
        } else {
            // submit
            let createDeviceRequest: any={
                name: createDeviceParameters.name,
                remarks: createDeviceParameters.remarks,
                deviceNo: createDeviceParameters.deviceNo,
                deviceTemplateId: createDeviceParameters.deviceTemplate.id,
                nodeId: createDeviceParameters.node.id,
                positioningMethod: 'AUTO',
            };

            let submit = async ()=> {
                await deviceApi.create(createDeviceRequest);
                console.log("Create device successfully");
                messageApi.open({
                    type: 'success',
                    content: '创建设备成功',
                });
                props.onComplete();
                props.onClose();
            }
            submit();
        }
    }

    let onPrevious = ()=> {
        if (step > 0) {
            setStep(step-1);
        }
    }

    let onCancel = ()=> {
        props.onClose();
    }

    let getStepTitle = (step)=> {
        switch(step) {
            case 0:
                return '请选择设备模板';
            case 1:
                return '请选择您的设备';
            case 2:
                return '完善您的设备信息';
        }
        return '';
    }

    let getStepContent = (step)=> {
        switch(step) {
            case 0: 
                return <ChooseDeviceTemplateStep 
                    createDeviceParameters={createDeviceParameters} 
                    setCanNext={(canNext)=> setCanNext(canNext)} 
                    setCreateDeviceParameters={(createDeviceParameters)=> setCreateDeviceParameters(createDeviceParameters)}
                />
            case 1: 
                return <ChooseNodeStep 
                    createDeviceParameters={createDeviceParameters} 
                    setCanNext={(canNext)=> setCanNext(canNext)} 
                    setCreateDeviceParameters={(createDeviceParameters)=> setCreateDeviceParameters(createDeviceParameters)}
                />
            case 2:
                return <FillBasicStep 
                    createDeviceParameters={createDeviceParameters} 
                    setCanNext={(canNext)=> setCanNext(canNext)} 
                    setCreateDeviceParameters={(createDeviceParameters)=> setCreateDeviceParameters(createDeviceParameters)}
                />
        }
        return 0;
    }

    // render
    return (
        <CreateDeviceWizardWrapper>
            {contextHolder}
            <ProgressStatusWrapper>
                <Space size="middle">
                    <StepTitle>{getStepTitle(step)}</StepTitle>
                </Space>
            </ProgressStatusWrapper>
            <ProgressContent>
                {getStepContent(step)}
            </ProgressContent>
            <OperationWrapper>
                <Space size="middle">
                    { step > 0 && <Button type="primary" onClick={onPrevious}>上一步</Button> }
                </Space>
                <Space size="middle">
                    <Button type="primary" disabled={!canNext} onClick={onNext}> { step < 2 ? '下一步' : '完成' }</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </CreateDeviceWizardWrapper>
    )
}

const ChooseDeviceTemplateStep: React.FC<CreateDeviceStepProps> = (props)=> {
    // api
    let deviceTemplateApi = new DeviceTemplateApi();
    
    // state
    let [deviceTemplates, setDeviceTemplates] = useState([]);
    let [selectedTemplateId, setSelectedTemplateId] = useState(props.createDeviceParameters.deviceTemplateId);

    // events
    let onSelectDeviceTemplate = (deviceTemplate)=> {
        setSelectedTemplateId(deviceTemplate.id);
        props.createDeviceParameters.deviceTemplate = deviceTemplate;
        props.createDeviceParameters.deviceTemplateId = deviceTemplate.id;
        props.setCreateDeviceParameters(props.createDeviceParameters);
        props.setCanNext(true);
    };

    // utils
    let getNodeTypeText = (nodeType)=> {
        if(nodeType=='DIRECT') {
            return '直连设备';
        } else if(nodeType=='GATEWAY') {
            return '网关';
        } else if(nodeType=='SLAVE') {
            return '子设备';
        }
        return '未知';
    }

    // columns definition
    let columns: ColumnType<any>[] = [
        /*
        {
            title: '',
            key: 'selection',
            align: 'center',
            render: (text, record, index)=> {
                return <Radio checked={ selectedTemplateId==record.id} onChange={onSelectTemplate(record.id)}></Radio>
            }
        },
        */
        {
            title: '模板名称',
            dataIndex: 'deviceTemplate.name',
            key: 'deviceTemplate.name',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.name}</span>
            }
        },
        {
            title: '产品型号',
            dataIndex: 'deviceTemplate.productModel',
            key: 'deviceTemplate.productModel',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.productModel}</span>
            }
        },
        {
            title: '类型',
            dataIndex: 'deviceTemplate.nodeType',
            key: 'deviceTemplate.nodeType',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{getNodeTypeText(record.nodeType)}</span>
            }
        },
        {
            title: '备注',
            dataIndex: 'deviceTemplate.remarks',
            key: 'deviceTemplate.remarks',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(record.remarks)}</span>
            }
        }
    ];

    // effect
    useEffect(()=> {
        let loadDeviceTemplates = async ()=> {
            let deviceTemplates = (await deviceTemplateApi.getAll()).data.data;
            setDeviceTemplates(deviceTemplates);
        }
        loadDeviceTemplates();

        if(props.createDeviceParameters.deviceTemplateId) {
            props.setCanNext(true);
        } else {
            props.setCanNext(false);
        }
    }, []);

    // render
    return (
        <Table 
            columns={columns} 
            dataSource={deviceTemplates} 
            rowKey={(record)=> record.id}
            rowSelection={{
                type: 'radio', 
                onSelect: (record)=> {onSelectDeviceTemplate(record)},
                selectedRowKeys: [selectedTemplateId],
            }}
            onRow={(record)=> {
                return {
                    onClick: (e)=> {
                        onSelectDeviceTemplate(record);
                    }
                }
            }}
            />
    )
}

const ChooseNodeStep: React.FC<CreateDeviceStepProps> = (props)=> {
    // api
    let nodeApi = new NodeApi();

    // state
    let [nodeViews, setNodeViews] = useState([]);
    let [mapCenter, setMapCenter] = useState([116, 39]);
    let [selectedNode, setSelectedNode] = useState(null);
    let [mapInfoOpen, setMapInfoOpen] = useState(false);

    // events
    let updateCreateDeviceParameters = (selectedNode: any)=> {
        if (selectedNode) {
            props.createDeviceParameters.node = selectedNode.node;
            props.createDeviceParameters.nodeId = selectedNode.node.id;
            props.setCreateDeviceParameters(props.createDeviceParameters);
            props.setCanNext(true);
        }
    };

    let onNodeMarkerClick = (node: any)=> {
        setSelectedNode(node);
        setMapInfoOpen(true);
        updateCreateDeviceParameters(node);
    };

    let onSelectNode = (node: any)=> {
        setSelectedNode(node);
        setMapInfoOpen(true);
        
        if (node.latestHeartBeatMetric) {
            setMapCenter([node.latestHeartBeatMetric.longitude, node.latestHeartBeatMetric.latitude]);
        }
        updateCreateDeviceParameters(node);
    }

    // effects
    useEffect(()=> {
        // load node data
        let loadNodes = async ()=> {
            let nodeViews = (await nodeApi.getNodeViewsForCreateDevice(props.createDeviceParameters.deviceTemplate.productModel)).data.data;
            setNodeViews(nodeViews);
            
            let canNext=false;
            if(props.createDeviceParameters.nodeId) {
                for(let nodeView of nodeViews) {
                    if (nodeView.node.id==props.createDeviceParameters.nodeId) {
                        onSelectNode(nodeView);
                        canNext = true;
                        break;
                    }
                }
            }
            props.setCanNext(canNext);
        }
        loadNodes();
    }, []);

    // render
    return (
        <ChooseNodeStepWrapper>
            <NodeListWrapper>
                <NodeListItem style={{backgroundColor: '#EEEEEE', fontWeight: 'bold', marginBottom: '5px'}}>
                    <NodeProductModel>型号</NodeProductModel>
                    <NodeSn>序列号</NodeSn>
                </NodeListItem>
                {
                    nodeViews.map((e)=> {
                        return (
                            <NodeListItem 
                                key={e.node.id} 
                                style={{cursor: 'pointer', backgroundColor: e.node.id==selectedNode?.node?.id ? '#EEEEEE': ''}}
                                onClick={()=> onSelectNode(e)}
                            >
                                <NodeProductModel>{e.node.model}</NodeProductModel>
                                <NodeSn>{e.node.sn}</NodeSn>
                            </NodeListItem>
                        )
                    })
                }
            </NodeListWrapper>

            <NodeMapWrapper>
            <Amap
                    mapStyle='amap://styles/dark'
                    zoom={4}
                    center={mapCenter}>
                    {
                        nodeViews.filter((e)=> {
                            return e.latestHeartBeatMetric!=null;
                        }).map((e)=> {
                            return <CircleMarker
                                onClick={()=> { onNodeMarkerClick(e); }}
                                key={e.id} 
                                center={[e.latestHeartBeatMetric.longitude, e.latestHeartBeatMetric.latitude]} 
                                radius={6} 
                                fillColor='rgba(249, 125, 90)' 
                                fillOpacity={0.8} 
                                strokeWeight={0}
                                cursor="pointer" />
                        })
                    }

                    {
                        selectedNode && (
                            <InfoWindow 
                                visible={mapInfoOpen} 
                                position={selectedNode.latestHeartBeatMetric ? [selectedNode.latestHeartBeatMetric.longitude, selectedNode.latestHeartBeatMetric.latitude] : mapCenter}
                                content={`
                                    <div>设备型号: ${selectedNode.node.model}</div>
                                    <div>序列号: ${selectedNode.node.sn}</div>
                                `}
                                onClose={()=> {
                                    setMapInfoOpen(false);
                                }}
                            >
                            </InfoWindow>
                        )
                    }
                </Amap>
            </NodeMapWrapper>
        </ChooseNodeStepWrapper>
    )
}

export const FillBasicStep: React.FC<CreateDeviceStepProps> = (props)=> {
    const [form] = Form.useForm();

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            props.setCanNext(true);
        }).catch((err)=> {
            props.setCanNext(false);
        });
    }

    // events
    let onDeviceNameChange = (e)=> {
        props.createDeviceParameters.name = e.target.value;
        props.setCreateDeviceParameters(props.createDeviceParameters);
        validateForm();
    };

    let onDeviceNoChange = (e)=> {
        props.createDeviceParameters.deviceNo = e.target.value;
        props.setCreateDeviceParameters(props.createDeviceParameters);
        validateForm();
    };

    let onRemarksChange = (e)=> {
        props.createDeviceParameters.remarks = e.target.value;
        props.setCreateDeviceParameters(props.createDeviceParameters);
        validateForm();
    }
    
    // effects
    useEffect(()=> {
        form.setFieldValue('deviceTemplateName', props.createDeviceParameters.deviceTemplate.name);
        form.setFieldValue('nodeSn', props.createDeviceParameters.node.sn);
    }, []);

    return (
        <Form form={form} name="form" layout="vertical">
            <Form.Item name="name" label="设备名称" rules={[{required: true}]}>
                <Input onChange={onDeviceNameChange} />
            </Form.Item>

            <Form.Item name="deviceNo" label="设备编号" rules={[{required: true}]}>
                <Input onChange={onDeviceNoChange} />
            </Form.Item>

            <Form.Item name="deviceTemplateName" label="设备模板" rules={[{required: true}]}>
                <Input disabled={true}/>
            </Form.Item>

            <Form.Item name="nodeSn" label="设备序列号" rules={[{required: true}]}>
                <Input disabled={true}/>
            </Form.Item>

            <Form.Item name="remarks" label="备注" rules={[{required: false}]}>
                <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange}/>
            </Form.Item>
        </Form>
    )
}

interface CreateDeviceStepProps {
    createDeviceParameters: CreateDeviceParameters;
    setCanNext: (nextEnabled: boolean)=> void;
    setCreateDeviceParameters: (createDeviceParameters: CreateDeviceParameters)=> void;
}

class CreateDeviceParameters {
    // targeted device template
    deviceTemplate: any;
    deviceTemplateId: string;

    // node
    node: any;
    nodeId: string;

    // basic information
    name: string;
    deviceNo: string;
    remarks: string;
}

export default CreateDeviceWizard;
