import styled from "styled-components";

export const DeviceHealthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-size: 12px;
    letter-spacing: 2px;
    padding: 15px 6px 6px 36px;
`;

export const ChartWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

export const ChartHeader = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 42px;
`;

export const ChartName = styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-right: 10px;
    padding-bottom: 5px;
    white-space: nowrap;
`;

export const ChartHeaderColorBlock = styled.div`
    width: 3px;
    height: 25px;
`;

export const ChartCurrentValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-left: 10px;
`;

export const ChartInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const ChartLatestMetricTime = styled.div`
    font-size: 11px;
`;

export const ChartUnit = styled.div`
    font-size: 11px;
`;

export const EChartsWrapper = styled.div`
    flex: 1 1 auto;
`;

