
import { Button, Form, Input, Select, Space, Table, Transfer, Upload, message } from "antd";
import { useEffect, useState } from "react";
import FirmwareApi from "../../../api/FirmwareApi";
import FirmwarePushTaskApi from "../../../api/FirmwarePushTaskApi";
import StringUtils from "../../../util/StringUtils";
import { TableRowSelection } from "antd/es/table/interface";
import NodeApi from "../../../api/NodeApi";
import { CreateFirmwarePushTaskWizardWrapper, FormWrapper, OperationWrapper, Title } from "./CreateFirmwarePushTaskWizard.styled";
import { TitleWrapper } from "../../firmware-management/components/create-firmware/CreateFirmwareWizard.styled";


interface CreateFirmwarePushTaskWizardProps {
    targetFirmware?: any
    onClose: ()=> void,
    onComplete: (firmwarePushTask: any)=> void,
}

const CreateFirmwarePushTaskWizard: React.FC<CreateFirmwarePushTaskWizardProps> = (props)=> {
    // api
    let nodeApi = new NodeApi();
    let firmwareApi = new FirmwareApi();
    let firmwarePushTaskApi = new FirmwarePushTaskApi();
    const [messageApi, contextHolder] = message.useMessage();

    // form
    let [form] = Form.useForm();

    // state
    let [submitEnabled, setSubmitEnabled] = useState(false);
    let [firmwares, setFirmwares] = useState([]);
    let [productModelOptions, setProductModelOptions] = useState([]);
    let [productFirmwareOptions, setProductFirmwareOptions] = useState([]);
    let [nodeViews, setNodeViews] = useState([]);

    let [selectedProductModel, setSelectedProductModel] = useState(null);
    let [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState(null);

    let [createFirmwarePushTaskParameters, setCreateFirmwarePushTaskParameters] = useState({
        productModel: props.targetFirmware?.productModel,
        firmwareId: props.targetFirmware?.id
    } as any);

    // functions
    let loadFirmwares = async ()=> {
        firmwares = (await firmwareApi.getAll()).data.data;
        let productModelOptions = [];
        let productModels = [];

        for(let firmware of firmwares) {
            if (productModels.indexOf(firmware.productModel)>=0) {
                continue;
            }
            productModels.push(firmware.productModel);
            productModelOptions.push({
                label: firmware.productModel,
                value: firmware.productModel
            });
        }
        setFirmwares(firmwares);
        setProductModelOptions(productModelOptions);

        if (createFirmwarePushTaskParameters.productModel) {
            onProductModelChange(createFirmwarePushTaskParameters.productModel);
        }
    };

    let loadNodeViews = async ()=> {
        let nodeViews = (await nodeApi.getNodeViewsForOTA(createFirmwarePushTaskParameters.productModel)).data.data;
        setNodeViews(nodeViews);
    }

    let filterOption = (value, item)=> {
        if (item.node.sn.indexOf(value) >= 0) {
            return true;
        }
        if (item.node.firmwareVersion.indexOf(value) >= 0) {
            return true;
        }
        if (StringUtils.nonull(item.node.remarks).indexOf(value) >= 0) {
            return true;
        }
        if (StringUtils.nonull(item.device?.name).indexOf(value) >= 0) {
            return true;
        }
        if (StringUtils.nonull(item.device?.no).indexOf(value) >= 0) {
            return true;
        }
        return false;
    };

    // event
    let onProductModelChange = (selectedValue: string)=> {
        createFirmwarePushTaskParameters.productModel = selectedValue;
        let productFirmwares = firmwares.filter(e=> StringUtils.equalsIgnoreCase(e.productModel, selectedValue))
        let productFirmwareOptions = productFirmwares.map(e=> {
            return {label: e.version, value: e.id};
        });
        setSelectedProductModel(selectedValue);
        setProductFirmwareOptions(productFirmwareOptions);
        loadNodeViews();
        validateForm();
    };

    let onProductFirmwareVersionChange = (selectedValue)=> {
        createFirmwarePushTaskParameters.firmwareId = selectedValue;
        setSelectedFirmwareVersion(firmwares.filter(e=>StringUtils.equalsIgnoreCase(e.id, selectedValue))[0].version);
        validateForm();
    };

    let handleTransferChange = (selectedKeys)=> {
        createFirmwarePushTaskParameters.nodeIds = selectedKeys;
        setCreateFirmwarePushTaskParameters({...createFirmwarePushTaskParameters});
        validateForm();
    };

    let onRemarksChange = (e)=> {
        createFirmwarePushTaskParameters.remarks = e.target.value;
        validateForm();
    };

    let onSubmit = async ()=> {
        let firmwarePushTask = (await firmwarePushTaskApi.pushFirmware(createFirmwarePushTaskParameters)).data.data;
        props.onComplete(firmwarePushTask);
        props.onClose();
    };

    let onCancel = ()=> {
        props.onClose();
    };

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    }

    // effect
    useEffect(()=> {
        loadFirmwares();
    }, []);

    // render
    const columns: any[] = [
        {
            dataIndex: 'device.name',
            title: '设备名',
            key: 'device.name',
            align: 'center',
            render: (text, record, index)=> {
                return StringUtils.emptyToSlash(record.device?.name);
            }
        },
        {
            dataIndex: 'device.no',
            title: '设备编号',
            key: 'device.no',
            align: 'center',
            render: (text, record, index)=> {
                return StringUtils.emptyToSlash(record.device?.no);
            }
        },
        {
            dataIndex: 'node.sn',
            title: 'SN',
            key: 'node.sn',
            align: 'center',
            render: (text, record, index)=> {
                return record.node.sn;
            }
        },
        {
            dataIndex: 'node.firmwareVersion',
            title: '当前版本',
            key: 'node.firmwareVersion',
            align: 'center',
            render: (text, record, index)=> {
                return record.node.firmwareVersion;
            }
        },
    ];

    return (
        <CreateFirmwarePushTaskWizardWrapper>
            <TitleWrapper>
                <Title>新建固件推送任务</Title>
            </TitleWrapper>

            <FormWrapper>
                <Form form={form} name="form" layout="vertical">
                    <Form.Item name="productModel" label="产品型号" rules={[{required: true}]} initialValue={createFirmwarePushTaskParameters.productModel}>
                        <Select
                            placeholder="请选择产品型号"
                            defaultValue={createFirmwarePushTaskParameters.productModel}
                            value={createFirmwarePushTaskParameters.productModel}
                            onChange={onProductModelChange}
                            options={productModelOptions}
                            disabled={props.targetFirmware!=null}
                        />
                    </Form.Item>

                    <Form.Item name="version" label="版本号" rules={[{required: true}]} initialValue={createFirmwarePushTaskParameters.firmwareId}>
                        <Select
                            placeholder="请选择固件版本"
                            defaultValue={createFirmwarePushTaskParameters.firmwareId}
                            value={createFirmwarePushTaskParameters.firmwareId}
                            onChange={onProductFirmwareVersionChange}
                            options={productFirmwareOptions}
                            disabled={props.targetFirmware!=null}
                        />
                    </Form.Item>

                    <Form.Item name="file" label="目标设备" rules={[{required: true}]}>
                        <Transfer style={{ width: '100%' }} 
                            showSelectAll={false} 
                            onChange={handleTransferChange}
                            filterOption={filterOption}
                            dataSource={nodeViews}
                            rowKey={(record) => record.node.id}
                            targetKeys={createFirmwarePushTaskParameters.nodeIds}
                            >
                            {({
                                direction,
                                filteredItems,
                                onItemSelect,
                                onItemSelectAll,
                                selectedKeys: listSelectedKeys,
                                disabled: listDisabled,
                            }) => {
                                const rowSelection: TableRowSelection<any> = {
                                    getCheckboxProps: () => ({ disabled: listDisabled }),
                                    onChange(selectedRowKeys) {
                                        console.log('direction', direction, selectedRowKeys);
                                        onItemSelectAll(selectedRowKeys, 'replace');
                                    },
                                    selectedRowKeys: listSelectedKeys,
                                    selections: [Table.SELECTION_ALL],
                                };

                                return (
                                    <Table
                                        rowKey={(record)=> record.node.id}
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={filteredItems}
                                        size="small"
                                        style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                                        onRow={({ key, disabled: itemDisabled }) => ({
                                            onClick: () => {
                                                if (itemDisabled || listDisabled) {
                                                    return;
                                                }
                                                onItemSelect(key, !listSelectedKeys.includes(key));
                                            },
                                        })}
                                    />
                                );
                            }}
                        </Transfer>
                    </Form.Item>

                    <Form.Item name="remarks" label="备注" rules={[{required: false}]}>
                        <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange} placeholder="请输入固件备注"/>
                    </Form.Item>
                </Form>
            </FormWrapper>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!submitEnabled} onClick={onSubmit}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </CreateFirmwarePushTaskWizardWrapper>
    )
}

export default CreateFirmwarePushTaskWizard;
