import { Button, Form, Input, Space, Switch, message } from "antd";
import { useEffect, useState } from "react";
import UserApi from "../../../api/UserApi";
import { CreateUserWizardWrapper, FormWrapper, OperationWrapper, Title, TitleWrapper } from "./CreateUserWizard.styled";

interface CreateUserWizardProps {
    editingUserId?: string,

    onClose: ()=> void,
    onComplete: ()=> void
}

const CreateUserWizard: React.FC<CreateUserWizardProps> = (props)=> {
    // api
    let userApi = new UserApi();
    const [messageApi, contextHolder] = message.useMessage();

    // form
    const [form] = Form.useForm();

    // state
    let [user, setUser] = useState<any>({});
    let [userRoles, setUserRoles] = useState([]);
    let [userPermissions, setUserPermissions] = useState([]);
    let [submitEnabled, setSubmitEnabled] = useState(false);

    // functions
    let loadUserProfileAndPermissions = async ()=> {
        let user = (await userApi.get(props.editingUserId)).data.data;
        form.setFieldsValue(user);
        setUser(user);
    }

    // event
    let onNameChange = (e)=> {
        user.name = e.target.value;
        validateForm();
    };

    let onPhoneNumberChange = (e)=> {
        user.phoneNumber = e.target.value;
        validateForm();
    };

    let onEmailChange = (e)=> {
        user.phoneNumber = e.target.value;
        validateForm();
    };

    let onAdminChange = (value)=> {
        user.admin = value;
        validateForm();
    };

    let onPasswordChange = (e)=> {
        user.password = e.target.value;
        validateForm();
    }
    
    let onRemarksChange = (e)=> {
        user.remarks = e.target.value;
        validateForm();
    };

    let onSubmit = async ()=> {
        if (props.editingUserId) {
            await userApi.update(user);
        } else {
            await userApi.create(user);
        }
        props.onComplete();
        props.onClose();
    };

    let onCancel = ()=> {
        props.onClose();
    };

    // validate
    let validateForm = ()=> {
        form.validateFields().then((values)=> {
            setSubmitEnabled(true);
        }).catch((err)=> {
            console.log(err);
        });
    }

    // effect
    useEffect(()=> {
    }, []);
    
    useEffect(()=> {
        if (props.editingUserId) {
            loadUserProfileAndPermissions();
        }
    }, [props.editingUserId])

    // render
    return (
        <CreateUserWizardWrapper>
            <TitleWrapper>
                <Title>{props.editingUserId ? '编辑用户' : '新增用户'}</Title>
            </TitleWrapper>

            <FormWrapper>
                <Form form={form} name="validateOnly" layout="vertical">
                    <Form.Item name="name" label="用户名" rules={[{required: true}]} initialValue={user.name}>
                        <Input value={user.name} onChange={onNameChange} placeholder="请输入用户名" />
                    </Form.Item>

                    <Form.Item name="phoneNumber" label="电话号码" rules={[{required: false}]} initialValue={user.phoneNumber}>
                        <Input value={user.phoneNumber} onChange={onPhoneNumberChange} placeholder="请输入手机号" />
                    </Form.Item>

                    <Form.Item name="email" label="电子邮箱" rules={[{required: false}]} initialValue={user.email}>
                        <Input value={user.phoneNumber} onChange={onEmailChange} placeholder="请输入电子邮箱" />
                    </Form.Item>

                    <Form.Item name="admin" label="管理员用户" rules={[{required: false}]} initialValue={user.admin}>
                        <Switch checked={user.admin} onChange={onAdminChange}/> 
                    </Form.Item>

                    <Form.Item name="password" label="密码" rules={[{required: props.editingUserId ? false: true}]} initialValue={user.password}>
                        <Input.Password value={user.password} onChange={onPasswordChange} placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item name="remarks" label="备注" rules={[{required: false}]} initialValue={user.remarks}>
                        <Input.TextArea style={{height: '100px'}} onChange={onRemarksChange} placeholder="请输入用户备注"/>
                    </Form.Item>
                </Form>
            </FormWrapper>

            <OperationWrapper>
                <Space size="middle">
                    <Button type="primary" disabled={!submitEnabled} onClick={onSubmit}>确定</Button>
                    <Button type="primary" danger onClick={onCancel}>取消</Button>
                </Space>
            </OperationWrapper>
        </CreateUserWizardWrapper>
    )
}

export default CreateUserWizard;
