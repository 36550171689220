import { MoreOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import { BorderLeftBlock, BorderLeftCircle, BorderLine, BorderRightBlock, Content, ContentCardWrapper, Title, TitleBorder, TitleCn, TitleEn, TitleSeparator } from "./ContentCard.styled";
import { CSSProperties } from "styled-components";

interface ContentCardProps {
    titleCn: string,
    titleEn: string,
    style?: CSSProperties,
    children?: ReactNode[] | ReactNode,
}

let ContentCard: React.FC<ContentCardProps> = (props)=> {

    return <ContentCardWrapper style={props.style}>
        <Title>
            <TitleCn>{props.titleCn}</TitleCn>
            <TitleSeparator> / </TitleSeparator>
            <TitleEn>{props.titleEn}</TitleEn>
            <div style={{flex: '1 1 auto'}} />
            <MoreOutlined style={{fontSize: '16px'}} />
        </Title>

        <TitleBorder>
            <BorderLeftCircle />
            <BorderLeftBlock />
            <BorderLine />
            <BorderRightBlock />
        </TitleBorder>

        <Content>
            {props.children}
        </Content>
    </ContentCardWrapper>
};

export default ContentCard;

