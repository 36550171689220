import styled from "styled-components";

export const CreateDeviceTemplateWizardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 700px;
    box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
    flex: 0 0 auto;
    margin: 10px 0px;
`;

export const Title = styled.div`
    font-size: 18px;
`;

export const FormWrapper = styled.div`
    flex: 1 1 auto;
`;

export const UploadIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;

    & span {
        font-size: 48px;
        color: #1677FF;
    }
`;

export const UploadText = styled.div`
    text-align: center;
    font-size: 16px;
    padding-top: 10px;
`;

export const UploadHints = styled.div`
    text-align: center;
    font-size: 14px;
    color: #999999;
`;

export const OperationWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0px;
`;
