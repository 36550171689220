
import styled from "styled-components";

export const DeviceAlarmsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
`;

export const AlarmItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;

    &:hover {
        background-color: rgba(180, 180, 180, 0.2);
    }
`;

export const DeviceName = styled.div`
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 10px 0px 0px;
`;

export const EventTime = styled.div`
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 10px 0px 0px;
`;

export const AlarmMessage = styled.div`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

