import styled from "styled-components";


export const DeviceRealtimeMetricsWrapper = styled.div`
    font-size: 16px;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 6px 6px 18px 36px;
`;

export const TableHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 5px;
`;

export const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const TableHeaderRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1.5fr 1fr 2fr;
    grid-gap: 10px;
    align-items: stretch;
    justify-items: stretch;
    background-color: rgba(255, 255, 255, 0.05);
    font-size: 14px;
    
    & div {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const TableRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1.5fr 1fr 2fr;
    grid-gap: 10px;
    align-items: stretch;
    justify-items: stretch;
    font-size: 14px;
    margin: 4px 0px;

    &:nth-child(even) {
        background-color: rgba(20, 26, 39, 0.95);
    }
    
    &:hover {
        background-color: rgba(90, 95, 110, 0.8);
    }

    &:hover:nth-child(even) {
        background-color: rgba(90, 95, 110, 0.8);
    }

`;

export const VariableName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
`;

export const VariableValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
`;

export const VariableUnit = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    text-align: center;
`;

export const MetricsUpdateTime = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
`;

