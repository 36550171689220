import { AxiosResponse } from "axios";
import TreeBaseApi from "./TreeBaseApi";
import AxiosService from "./Axios";

const BASE_URL = "/device-group";

class DeviceGroupApi extends TreeBaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getDeviceTreeView(): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/device-tree-view');
    }
}

export default DeviceGroupApi;
