import axios, { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class WeatherApi {
    
    public async query(): Promise<AxiosResponse<any>> {
        return AxiosService.get('/amap/weather/query');
    }

}

export default WeatherApi;