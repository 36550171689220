let predefindUnits = [
    {
        "symbol":"L/min",
        "name":"升每分钟"
    },
    {
        "symbol":"mg/kg",
        "name":"毫克每千克"
    },
    {
        "symbol":"NTU",
        "name":"浊度"
    },
    {
        "symbol":"pH",
        "name":"PH值"
    },
    {
        "symbol":"dS/m",
        "name":"土壤EC值"
    },
    {
        "symbol":"W/㎡",
        "name":"太阳总辐射"
    },
    {
        "symbol":"mm/hour",
        "name":"降雨量"
    },
    {
        "symbol":"var",
        "name":"乏"
    },
    {
        "symbol":"cP",
        "name":"厘泊"
    },
    {
        "symbol":"aw",
        "name":"饱和度"
    },
    {
        "symbol":"pcs",
        "name":"个"
    },
    {
        "symbol":"cst",
        "name":"厘斯"
    },
    {
        "symbol":"bar",
        "name":"巴"
    },
    {
        "symbol":"ppt",
        "name":"纳克每升"
    },
    {
        "symbol":"ppb",
        "name":"微克每升"
    },
    {
        "symbol":"uS/cm",
        "name":"微西每厘米"
    },
    {
        "symbol":"N/C",
        "name":"牛顿每库仑"
    },
    {
        "symbol":"V/m",
        "name":"伏特每米"
    },
    {
        "symbol":"ml/min",
        "name":"滴速"
    },
    {
        "symbol":"mmHg",
        "name":"毫米汞柱"
    },
    {
        "symbol":"mmol/L",
        "name":"血糖"
    },
    {
        "symbol":"mm/s",
        "name":"毫米每秒"
    },
    {
        "symbol":"turn/m",
        "name":"转每分钟"
    },
    {
        "symbol":"count",
        "name":"次"
    },
    {
        "symbol":"gear",
        "name":"档"
    },
    {
        "symbol":"stepCount",
        "name":"步"
    },
    {
        "symbol":"Nm3/h",
        "name":"标准立方米每小时"
    },
    {
        "symbol":"kV",
        "name":"千伏"
    },
    {
        "symbol":"kVA",
        "name":"千伏安"
    },
    {
        "symbol":"kVar",
        "name":"千乏"
    },
    {
        "symbol":"uw/cm2",
        "name":"微瓦每平方厘米"
    },
    {
        "symbol":"只",
        "name":"只"
    },
    {
        "symbol":"%RH",
        "name":"相对湿度"
    },
    {
        "symbol":"m³/s",
        "name":"立方米每秒"
    },
    {
        "symbol":"kg/s",
        "name":"公斤每秒"
    },
    {
        "symbol":"r/min",
        "name":"转每分钟"
    },
    {
        "symbol":"t/h",
        "name":"吨每小时"
    },
    {
        "symbol":"KCL/h",
        "name":"千卡每小时"
    },
    {
        "symbol":"L/s",
        "name":"升每秒"
    },
    {
        "symbol":"Mpa",
        "name":"兆帕"
    },
    {
        "symbol":"m³/h",
        "name":"立方米每小时"
    },
    {
        "symbol":"kvarh",
        "name":"千乏时"
    },
    {
        "symbol":"μg/L",
        "name":"微克每升"
    },
    {
        "symbol":"kcal",
        "name":"千卡路里"
    },
    {
        "symbol":"GB",
        "name":"吉字节"
    },
    {
        "symbol":"MB",
        "name":"兆字节"
    },
    {
        "symbol":"KB",
        "name":"千字节"
    },
    {
        "symbol":"B",
        "name":"字节"
    },
    {
        "symbol":"μg/(d㎡·d)",
        "name":"微克每平方分米每天"
    },
    {
        "symbol":"",
        "name":"无"
    },
    {
        "symbol":"ppm",
        "name":"百万分率"
    },
    {
        "symbol":"pixel",
        "name":"像素"
    },
    {
        "symbol":"Lux",
        "name":"照度"
    },
    {
        "symbol":"grav",
        "name":"重力加速度"
    },
    {
        "symbol":"dB",
        "name":"分贝"
    },
    {
        "symbol":"%",
        "name":"百分比"
    },
    {
        "symbol":"lm",
        "name":"流明"
    },
    {
        "symbol":"bit",
        "name":"比特"
    },
    {
        "symbol":"g/mL",
        "name":"克每毫升"
    },
    {
        "symbol":"g/L",
        "name":"克每升"
    },
    {
        "symbol":"mg/L",
        "name":"毫克每升"
    },
    {
        "symbol":"μg/m³",
        "name":"微克每立方米"
    },
    {
        "symbol":"mg/m³",
        "name":"毫克每立方米"
    },
    {
        "symbol":"g/m³",
        "name":"克每立方米"
    },
    {
        "symbol":"kg/m³",
        "name":"千克每立方米"
    },
    {
        "symbol":"nF",
        "name":"纳法"
    },
    {
        "symbol":"pF",
        "name":"皮法"
    },
    {
        "symbol":"μF",
        "name":"微法"
    },
    {
        "symbol":"F",
        "name":"法拉"
    },
    {
        "symbol":"Ω",
        "name":"欧姆"
    },
    {
        "symbol":"μA",
        "name":"微安"
    },
    {
        "symbol":"mA",
        "name":"毫安"
    },
    {
        "symbol":"kA",
        "name":"千安"
    },
    {
        "symbol":"A",
        "name":"安培"
    },
    {
        "symbol":"mV",
        "name":"毫伏"
    },
    {
        "symbol":"V",
        "name":"伏特"
    },
    {
        "symbol":"ms",
        "name":"毫秒"
    },
    {
        "symbol":"s",
        "name":"秒"
    },
    {
        "symbol":"min",
        "name":"分钟"
    },
    {
        "symbol":"h",
        "name":"小时"
    },
    {
        "symbol":"day",
        "name":"日"
    },
    {
        "symbol":"week",
        "name":"周"
    },
    {
        "symbol":"month",
        "name":"月"
    },
    {
        "symbol":"year",
        "name":"年"
    },
    {
        "symbol":"kn",
        "name":"节"
    },
    {
        "symbol":"km/h",
        "name":"千米每小时"
    },
    {
        "symbol":"m/s",
        "name":"米每秒"
    },
    {
        "symbol":"″",
        "name":"秒"
    },
    {
        "symbol":"′",
        "name":"分"
    },
    {
        "symbol":"°",
        "name":"度"
    },
    {
        "symbol":"rad",
        "name":"弧度"
    },
    {
        "symbol":"Hz",
        "name":"赫兹"
    },
    {
        "symbol":"μW",
        "name":"微瓦"
    },
    {
        "symbol":"mW",
        "name":"毫瓦"
    },
    {
        "symbol":"kW",
        "name":"千瓦特"
    },
    {
        "symbol":"W",
        "name":"瓦特"
    },
    {
        "symbol":"cal",
        "name":"卡路里"
    },
    {
        "symbol":"kW·h",
        "name":"千瓦时"
    },
    {
        "symbol":"Wh",
        "name":"瓦时"
    },
    {
        "symbol":"eV",
        "name":"电子伏"
    },
    {
        "symbol":"kJ",
        "name":"千焦"
    },
    {
        "symbol":"J",
        "name":"焦耳"
    },
    {
        "symbol":"℉",
        "name":"华氏度"
    },
    {
        "symbol":"K",
        "name":"开尔文"
    },
    {
        "symbol":"t",
        "name":"吨"
    },
    {
        "symbol":"°C",
        "name":"摄氏度"
    },
    {
        "symbol":"mPa",
        "name":"毫帕"
    },
    {
        "symbol":"hPa",
        "name":"百帕"
    },
    {
        "symbol":"kPa",
        "name":"千帕"
    },
    {
        "symbol":"Pa",
        "name":"帕斯卡"
    },
    {
        "symbol":"mg",
        "name":"毫克"
    },
    {
        "symbol":"g",
        "name":"克"
    },
    {
        "symbol":"kg",
        "name":"千克"
    },
    {
        "symbol":"N",
        "name":"牛"
    },
    {
        "symbol":"mL",
        "name":"毫升"
    },
    {
        "symbol":"L",
        "name":"升"
    },
    {
        "symbol":"mm³",
        "name":"立方毫米"
    },
    {
        "symbol":"cm³",
        "name":"立方厘米"
    },
    {
        "symbol":"km³",
        "name":"立方千米"
    },
    {
        "symbol":"m³",
        "name":"立方米"
    },
    {
        "symbol":"h㎡",
        "name":"公顷"
    },
    {
        "symbol":"c㎡",
        "name":"平方厘米"
    },
    {
        "symbol":"m㎡",
        "name":"平方毫米"
    },
    {
        "symbol":"k㎡",
        "name":"平方千米"
    },
    {
        "symbol":"㎡",
        "name":"平方米"
    },
    {
        "symbol":"nm",
        "name":"纳米"
    },
    {
        "symbol":"μm",
        "name":"微米"
    },
    {
        "symbol":"mm",
        "name":"毫米"
    },
    {
        "symbol":"cm",
        "name":"厘米"
    },
    {
        "symbol":"dm",
        "name":"分米"
    },
    {
        "symbol":"km",
        "name":"千米"
    },
    {
        "symbol":"m",
        "name":"米"
    }
];

class UnitUtils {

    public static getPredefinedUnits() {
        return predefindUnits;
    }
}

export default UnitUtils;
