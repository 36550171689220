import styled from "styled-components";

export const DeviceSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 5px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const SettingsGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 15px;
`;

export const GroupTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 15px 20px;
`;

export const GroupContent = styled.div`
    background-color: #F8F8F8;
    border-radius: 5px;
    overflow: hidden;
`;

export const SettingItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #E7E7E7;

    &:first-child {
        border-top: none;
    }

    &:hover {
        background-color: #F0F0F0;
    }
`;

export const Settinglabel = styled.div`
    flex: 0 0 auto;
    padding: 10px;
    box-sizing: border-box;
    width: 50%;
`;

export const SettingTitle = styled.div`
    font-size: 16px;
    margin: 5px;
`;

export const SettingDescription = styled.div`
    font-size: 11px;
    color: #666666;
    margin: 5px;
`;

export const SettingValue = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #101010;
`;

