import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = '/user';

class UserApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }
}

export default UserApi;

