import { AxiosResponse } from "axios";
import AxiosService from "./Axios";

class DeviceMetricApi {
    
    public async getLatestDeviceMetricsView(deviceId: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(`/device-metric/latest-device-metrics-view/${deviceId}`);
    }

    public async getRealtimeDeviceMetricsSeries(deviceId: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(`/device-metric/realtime-metrics-series/${deviceId}`);
    }

    public async queryHistoricalDeviceMetricsSeries(request: QueryHistoricalDeviceMetricsRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post(`/device-metric/history-metrics-series/device/query`, request);
    }

    public async exportHistoricalDeviceMetrics(request: ExportHistoryDeviceMetricsRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post(`/device-metric/history-metrics/device/export`, request);
    }

    public async getHistoricalDeviceMetricsExportTasks(): Promise<AxiosResponse<any>> {
        return AxiosService.get(`/device-metric/history-metrics/device/export-task/list`);
    }

}

interface QueryHistoricalDeviceMetricsRequest {
    deviceId: string,
    properties?: string[],
    startTime?: number,
    endTime?: number,
    aggregateType?: string,
    timeScale?: string,
}

interface ExportHistoryDeviceMetricsRequest {
    deviceId: string,
    properties?: string[],
    startTime?: number,
    endTime?: number,
    aggregateType?: string,
    timeScale?: string,
}

export default DeviceMetricApi;
