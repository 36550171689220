import styled from "styled-components";

export const DeviceNetworkTrafficWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const Title = styled.div`
    font-size: 18px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    overflow-y: hidden;
`;

export const DeviceTrafficStatisticsItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: rgba(180, 180, 180, 0.2);
    }
`;

export const DeviceName = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
`;

export const TrafficUsageBar = styled.div`
    flex: 2 2 0px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    margin: 0px 10px;
`;

export const TrafficUsed = styled.div`
    height: 20px;
    background-color: #F97D5B;
    opacity: 1.0;
`;

export const TrafficFree = styled.div`
    height: 20px;
    background-color: transparent;
    opacity: 0.5;
`;

export const TrafficUsageText = styled.div`
    flex: 0 0 auto;
    width: 110px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
`;
