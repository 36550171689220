interface AlarmEventManagementProps {

}

const AlarmEventManagement: React.FC<AlarmEventManagementProps> = (props)=> {
    // render
    return (
        <div>Alarm Events, Developing...</div>
    );
}

export default AlarmEventManagement;
