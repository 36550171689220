import { ColumnType, TablePaginationConfig } from "antd/es/table";
import { Content, DeviceOnlineOfflineHistoryManagementWrapper, FilterBar, FilterItem, FilterItemLabel, InfoItem, InfoItemRow, InfoLabel, InfoValue, OfflineEventType, OnlineEventType, OnlineOfflineEventDetailsWrapper, OperationWrapper, TableWrapper, Title, TitleWrapper, ToolBarWrapper } from "./DeviceOnlineOfflineHistoryManagement.styled";
import StringUtils from "../../util/StringUtils";
import moment from "moment";
import { Button, DatePicker, Modal, Select, Space } from "antd";
import OnlineOfflineEventApi from "../../api/OnlineOfflineEventApi";
import { useEffect, useState } from "react";
import { Table } from "antd/lib";
import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DeviceApi from "../../api/DeviceApi";

interface DeviceOnlineOfflineHistoryManagementProps {

}
    
interface TableParams {
    pagination?: TablePaginationConfig;
}  

const DeviceOnlineOfflineHistoryManagement: React.FC<DeviceOnlineOfflineHistoryManagementProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();
    let onlineOfflineEventApi = new OnlineOfflineEventApi();

    // state
    let [loading, setLoading] = useState(false);
    let [deviceId, setDeviceId] = useState(null);
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);
    let [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    
    let [deviceOptions, setDeviceOptions] = useState(null);
    let [onlineOfflineEventViews, setOnlineOfflineEventViews] = useState([]);
    let [openDetailedEventModal, setOpenDetailedEventModal] = useState(false);
    let [detailedEventView, setDetailedView] = useState(null as any);

    // functions
    let loadDevices = async ()=> {
        let devices = (await deviceApi.getAll()).data.data;
        let deviceOptions = devices.map((e)=> {
            return {
                label: e.name,
                value: e.id,
            };
        });
        setDeviceOptions(deviceOptions);
    };

    let loadEventViews = async ()=> {
        // loading animation
        setLoading(true);
        
        // process start/end time
        let startTimestamp = null;
        if (startTime!=null) {
            startTimestamp = dayjs(startTime).toDate().getTime();
        }
        let endTimestamp = null;
        if (endTime!=null) {
            endTimestamp = dayjs(endTime).toDate().getTime();
        }

        let onlineOfflineEventViewsPage = (await onlineOfflineEventApi.queryViews({
            deviceId: deviceId,
            startTime: startTimestamp,
            endTime: endTimestamp,
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        })).data.data;

        let newTableParams = {pagination: {...tableParams.pagination}};
        newTableParams.pagination.total = onlineOfflineEventViewsPage.totalElements;
        setTableParams(newTableParams);
        setOnlineOfflineEventViews(onlineOfflineEventViewsPage.elements);
        setLoading(false);
    };

    // events
    let onDeviceChange=(selectedValue: string)=> {
        setDeviceId(selectedValue);
    };

    let onDateTimeRangeChange = (dateTimeRangeValue: any[])=> {
        setStartTime(dateTimeRangeValue[0]);
        setEndTime(dateTimeRangeValue[1]);
    };

    let viewEventDetails = (eventView)=> {
        setDetailedView(eventView);
        setOpenDetailedEventModal(true);
    };

    let onQueryButtonClick = async ()=> {
        let pagination = tableParams.pagination;
        pagination.current = 1;
        tableParams.pagination = pagination;
        setTableParams({pagination: pagination});
        await loadEventViews();
    };

    let onTableChange = (pagination)=> {
        tableParams.pagination = pagination;
        setTableParams({pagination: pagination});
        loadEventViews();
    };

    // effect
    useEffect(()=> {
        loadDevices();
        loadEventViews();
    }, []);
    
    // column definition
    let columns: ColumnType<any>[] = [
        {
            title: '序列号',
            dataIndex: 'node.sn',
            key: 'node.sn',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{record.node.sn}</span>
            }
        },
        {
            title: '设备名称',
            dataIndex: 'device.name',
            key: "device.name",
            align: 'center',
            render: (text, record, index)=> {
                return StringUtils.emptyToSlash(record.device?.name);
            }
        },
        {
            title: '事件时间',
            dataIndex: 'event.eventTime',
            key: 'event.eventTime',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{ moment(parseInt(record.event.eventTime)).format("YYYY-MM-DD HH:mm:ss") }</span>
            }
        },
        {
            title: '事件类型',
            dataIndex: 'event.type',
            key: 'event.type',
            align: 'center',
            render: (text, record, index)=> {
                return getEventTypeText(record.event.type);
            }
        },
        {
            title: '事件详情',
            dataIndex: 'event.reason',
            key: 'event.reason',
            align: 'center',
            render: (text, record, index)=> {
                return <span>{StringUtils.emptyToSlash(getEventReasonText(record.event.reason))}</span>
            }
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record, index)=> {
                return <Space size="middle">
                    <a onClick={()=> viewEventDetails(record)}>查看详情</a>
                </Space>
            }
        }
    ];

    let getEventTypeText = (eventType: string)=> {
        if (eventType=='ONLINE') {
            return <OnlineEventType>设备上线</OnlineEventType>;
        } else if(eventType=='OFFLINE') {
            return <OfflineEventType>设备下线</OfflineEventType>;
        }
        return 'N/A';
    };

    let getEventReasonText = (reasonType: string)=> {
        if (reasonType=='POWER_ON') {
            return '设备上电';
        } else if (reasonType=='POWER_OUT') {
            return '设备断电';
        } else if(reasonType=='HEARTBEAT_TIMEOUT') {
            return '心跳超时';
        } else if(reasonType=='RECONNECT') {
            return '网络重连';
        }
        return reasonType;
    }

    // render
    return (
        <DeviceOnlineOfflineHistoryManagementWrapper>
            <ToolBarWrapper>
                <FilterBar>
                    <Space size="middle">
                        <FilterItem>
                            <FilterItemLabel>设备名称</FilterItemLabel>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="label"
                                filterSort={(a, b) => a <= b ? -1 : 1}
                                style={{ width: '150px' }}
                                placeholder="请选择目标设备"
                                defaultValue={deviceId}
                                value={deviceId}
                                onChange={onDeviceChange}
                                options={deviceOptions}
                            />
                        </FilterItem>

                        <FilterItem>
                            <FilterItemLabel>时间范围</FilterItemLabel>
                            <DatePicker.RangePicker
                                showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                defaultValue={[dayjs().add(-1, 'd'), dayjs()]}
                                value={[startTime, endTime]}
                                allowEmpty={[false, true]}
                                onOk={onDateTimeRangeChange}
                            />
                        </FilterItem>
                    </Space>

                    <Space size="large">
                        <Button type="primary" 
                            icon={<SearchOutlined style={{marginTop: '3px'}}/>} 
                            onClick={onQueryButtonClick}
                            className="query-button"
                        >查询</Button>
                        <Button 
                            type="primary" 
                            icon={<ExportOutlined />} 
                            onClick={onQueryButtonClick}
                        >导出</Button>
                    </Space>
                </FilterBar>
            </ToolBarWrapper>

            <TableWrapper>
                <Table
                    rowKey={(record)=> record.event.id}
                    columns={columns}
                    pagination={tableParams.pagination}
                    dataSource={onlineOfflineEventViews}
                    loading={loading}
                    onChange={onTableChange}
                />

                <Modal
                    open={openDetailedEventModal} 
                    onClose={()=> setOpenDetailedEventModal(false)} 
                    footer={null} 
                    closable={false}
                    width={600}
                >
                
                { detailedEventView && <OnlineOfflineEventDetailsWrapper>
                        <TitleWrapper>
                            <Title>事件详情</Title>
                        </TitleWrapper>

                        <Content>
                            <InfoItemRow>
                                <InfoItem>
                                    <InfoLabel>设备名称</InfoLabel>
                                    <InfoValue>{StringUtils.emptyToSlash(detailedEventView.device?.name)}</InfoValue>
                                </InfoItem>

                                <InfoItem>
                                    <InfoLabel>设备编号</InfoLabel>
                                    <InfoValue>{StringUtils.emptyToSlash(detailedEventView.device?.deviceNo)}</InfoValue>
                                </InfoItem>
                            </InfoItemRow>

                            <InfoItemRow>
                                <InfoItem>
                                    <InfoLabel>序列号SN</InfoLabel>
                                    <InfoValue>{StringUtils.emptyToSlash(detailedEventView.node.sn)}</InfoValue>
                                </InfoItem>

                                <InfoItem>
                                    <InfoLabel>产品型号</InfoLabel>
                                    <InfoValue>{StringUtils.emptyToSlash(detailedEventView.node.model)}</InfoValue>
                                </InfoItem>
                            </InfoItemRow>

                            <InfoItemRow>
                                <InfoItem>
                                    <InfoLabel>事件时间</InfoLabel>
                                    <InfoValue>{moment(parseInt(detailedEventView.event.eventTime)).format('YYYY/MM/DD HH:mm:ss')}</InfoValue>
                                </InfoItem>

                                <InfoItem>
                                    <InfoLabel>事件类型</InfoLabel>
                                    <InfoValue>{getEventTypeText(detailedEventView.event.type)}</InfoValue>
                                </InfoItem>
                            </InfoItemRow>
                        </Content>

                        <OperationWrapper>
                            <Space size="middle">
                                <Button type="primary" onClick={()=> setOpenDetailedEventModal(false)}>确定</Button>
                            </Space>
                        </OperationWrapper>
                    </OnlineOfflineEventDetailsWrapper>
                }
                </Modal>
            </TableWrapper>
        </DeviceOnlineOfflineHistoryManagementWrapper>
    );
}

export default DeviceOnlineOfflineHistoryManagement;
