import { useEffect, useState } from "react";
import moment from "moment";
import { Progress } from "antd";

import SessionService from "../../../../../../services/session/SessionService";
import { AccountWrapper, Avatar, Email, Information, Name, Operation, Operations, PhoneNumber, ProfileDetails } from "./Account.styled";
import { MailOutlined, MobileOutlined, PhoneOutlined, PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import StringUtils from "../../../../../../util/StringUtils";

interface TaskListProps {

}

let Account: React.FC<TaskListProps> = (props)=> {

    // data
    let profile = SessionService.getInstance().getCurrentUserProfile();

    let onSignOut = ()=> {
        SessionService.getInstance().setAuthorization('');
        window.location.href = '/';
    };

    // render
    return <AccountWrapper>
        <Information>
            <ProfileDetails>
                <Name><UserOutlined /> {profile.name} </Name>
                <PhoneNumber><MobileOutlined /> {StringUtils.emptyToDefault(profile.phoneNumber, "未填写手机号")} </PhoneNumber>
                <Email><MailOutlined /> {StringUtils.emptyToDefault(profile.email, '未填写电子邮箱')} </Email>
            </ProfileDetails>
        </Information>

        <Operations>
            <Operation onClick={onSignOut}><PoweroffOutlined style={{fontSize: '21px', paddingRight: "12px"}}/> 退出当前登录</Operation>
        </Operations>
    </AccountWrapper>
};

export default Account;
