

class ViewStateService {

    // status bar title state
    private statusBarTitle: string;

    private statusBarTitleChangeListeners: ViewStateChangeListener[] = [];
    private newTaskListener: OnNewTaskListener[] = [];

    public getStatusBarTitle(): string {
        return this.statusBarTitle;
    }

    public addStatusBarTitleChnageListener(listener: ViewStateChangeListener): void {
        if (this.statusBarTitleChangeListeners.indexOf(listener)>=0) {
            return;
        }
        this.statusBarTitleChangeListeners.push(listener);
    }

    public removeStatusBarTitleChnageListener(listener: ViewStateChangeListener): void {
        let index=this.statusBarTitleChangeListeners.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.statusBarTitleChangeListeners.splice(index, 1);
    }

    public setStatusBarTitle(statusBarTitle: string): void {
        if(this.statusBarTitle==statusBarTitle) {
            return;
        }
        this.statusBarTitle = statusBarTitle;
        setTimeout(()=> {
            this.fireStatusBarTitleListeners();
        });
    }

    public fireStatusBarTitleListeners(): void {
        for(let statusBarTitleChangeListener of this.statusBarTitleChangeListeners) {
            statusBarTitleChangeListener(this.statusBarTitle);
        }
    }

    public addNewTaskListener(listener: OnNewTaskListener): void {
        if (this.newTaskListener.indexOf(listener)>=0) {
            return;
        }
        this.newTaskListener.push(listener);
    }

    
    public removeNewTaskListener(listener: OnNewTaskListener): void {
        let index=this.newTaskListener.indexOf(listener);
        if (index < 0) {
            return;
        }
        this.newTaskListener.splice(index, 1);
    }

    public fireNewTaskListeners(): void {
        for(let newTaskListener of this.newTaskListener) {
            newTaskListener();
        }
    }

    private static viewStateService = new ViewStateService();

    public static getInstance(): ViewStateService {
        return this.viewStateService;
    }
}

interface ViewStateChangeListener {
    (value: any): void
}

interface OnNewTaskListener {
    (): void
}

export default ViewStateService;
