import { AxiosResponse } from "axios";
import AxiosService from "./Axios";
import Api from "./Api";

class BaseApi {

    protected baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(id: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(this.baseUrl + '/get', {
            params: { id: id }
        });
    }

    public async getAll(): Promise<AxiosResponse<any>> {
        return AxiosService.get(this.baseUrl + '/all');
    }

    public async delete(id: string): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/delete', {
            id: id
        });
    }

    public async create(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/create', data);
    }

    public async update(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/update', data);
    }

    public async query(data: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(this.baseUrl + '/query', {
            params: data
        });
    }

}

export default BaseApi;

