import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/device";

class DeviceApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getAllDeviceViews(): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/device-view/all');
    }

    public async queryViews(request: QueryDeviceViewsRequest): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/device-view/query', request);
    }

    public async getDeviceViewByDeviceId(deviceId: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/device-view/device-id/' + deviceId);
    }

    public async saveSettings(deviceId: string, type: string, settings: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/device-settings/save', {
            deviceId: deviceId,
            groupSettingsType: type,
            groupSettings: settings,
        });
    }

    public async invokeService(deviceId: string, serviceKey: string, parameters: any): Promise<AxiosResponse<any>> {
        return AxiosService.post(BASE_URL + '/service/invoke', {
            deviceId: deviceId,
            serviceKey: serviceKey,
            parameters: parameters,
        });
    }

}

interface QueryDeviceViewsRequest {
    pageNo: number,
    pageSize: number,

    deviceTemplateId?: string;
    productModel?: string;
    search?: string;
}

export default DeviceApi;
