import { Amap, CircleMarker } from "@amap/amap-react";
import DeviceApi from "../../../../../api/DeviceApi";
import { useEffect, useState } from "react";
import moment from "moment";
import { DeviceTrackWrapper } from "./DeviceTrack.styled";
import StringUtils from "../../../../../util/StringUtils";
import { DeviceStatusUtils } from "../../../../../components/ui-components/device-status/DeviceStatus";
import ScaleUtils from "../../../../../util/ScaleUtils";

interface DeviceTrackProps {
    deviceId: string;
}

let DeviceTrack: React.FC<DeviceTrackProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceView, setDeviceView] = useState(null);

    // functions
    let fetchDeviceViewData = async (deviceId)=> {
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);
    }

    let formatDateTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    }

    let getDeviceMarkerColor = (deviceView: any)=> {
        return DeviceStatusUtils.getDeviceMarkerColor(deviceView.node.status);
    };

    let initalizeDeviceMapWrapper = (dom: HTMLElement)=> {
        if (dom == null) {
            return;
        }
        dom.style.transform = `scale(${1/ScaleUtils.getGlobalScale()})`;
        dom.style.transformOrigin = 'left top';
        dom.style.width = `${ScaleUtils.getGlobalScale() * 100.0}%`;
        dom.style.height = `${ScaleUtils.getGlobalScale() * 100.0}%`;
    };

    // effect
    useEffect(()=> {
        fetchDeviceViewData(props.deviceId);
        let refreshTask = setInterval(async ()=> {
            await fetchDeviceViewData(props.deviceId);
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    // render
    return (
        <DeviceTrackWrapper ref={(dom)=> initalizeDeviceMapWrapper(dom)}>
            { deviceView && <Amap
                mapStyle='amap://styles/dark'
                zoom={11}
                center={[deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude]}>
                
                {
                    deviceView.latestHeartBeatMetric && (
                        <CircleMarker
                            center={[deviceView.latestHeartBeatMetric.longitude, deviceView.latestHeartBeatMetric.latitude]} 
                            radius={Math.round(6 * ScaleUtils.getGlobalScale())} 
                            fillColor={getDeviceMarkerColor(deviceView)}
                            fillOpacity={0.8} 
                            strokeWeight={0}
                            cursor="pointer" />
                    )
                }
            </Amap> }
        </DeviceTrackWrapper>
    )
};

export default DeviceTrack;
