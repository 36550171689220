import { useEffect, useState } from "react";
import { DownloadLink, ExportDeviceMetricsTask, NoTaskTips, TaskInformation, TaskListWrapper, TaskName, TaskProgress } from "./TaskList.styled";
import { TaskStatus } from "../../../../../firmware-push-managment/view-task/FirmwarePushTaskDetails.styled";
import moment from "moment";
import { Alert, Button, Popover, Progress } from "antd";
import DeviceMetricApi from "../../../../../../api/DeviceMetricApi";
import Api from "../../../../../../api/Api";
import SessionService from "../../../../../../services/session/SessionService";
import ViewStateService from "../../../../../../services/view-state/ViewStateService";

interface TaskListProps {

}

let TaskList: React.FC<TaskListProps> = (props)=> {

    // api
    let deviceMetricApi = new DeviceMetricApi();

    // state
    let [tasks, setTasks] = useState([]);

    // function
    let loadTasks = async ()=> {
        let tasks = (await deviceMetricApi.getHistoricalDeviceMetricsExportTasks()).data.data;
        setTasks(tasks);
    };

    let getTaskName = (task)=> {
        let taskDateTime = moment(new Date(parseInt(task.createTime))).format('YYYY-MM-DD HH:mm:ss');
        return `数据导出 ${task.deviceName} ${taskDateTime}`;
    };

    let getDownloadLink = (task)=> {
        return Api.URL_PREFIX + `/device-metric/history-metrics/device/export-task/download?taskId=${task.id}&authorization=${SessionService.getInstance().getAuthoriazation()}`;
    };

    let getProgressPercent = (task)=> {
        if (task.total <= 0 && task.status != 'DONE') {
            return 0;
        }
        if(task.total <= 0) {
            return 100.0;
        }
        return Math.floor(task.progress / task.total * 100.0);
    };

    let getProgressStatus = (task)=> {
        if (task.status == 'ERROR') {
            return 'exception';
        } else if(task.status == 'DONE') {
            return 'success';
        } else {
            return 'active';
        }
    }
    
    // effect
    useEffect(()=> {
        loadTasks();

        // refresh task
        let refreshInterval = window.setInterval(()=> {
            loadTasks();
        }, 5000);


        let taskNotificationListener = ()=> {
            loadTasks();
        };
        ViewStateService.getInstance().addNewTaskListener(taskNotificationListener);

        return ()=> {
            window.clearInterval(refreshInterval);
            ViewStateService.getInstance().removeNewTaskListener(taskNotificationListener);
        }
    }, []);

    // render
    return <TaskListWrapper>
        {
            tasks.map((task)=> {
                return <ExportDeviceMetricsTask key={task.id}>
                    <TaskInformation>
                        <TaskName>{getTaskName(task)}</TaskName>
                        { task.status == 'DONE' && <DownloadLink target="_blank" href={getDownloadLink(task)}>下载</DownloadLink> }

                        { task.status == 'ERROR' && 
                            <Popover 
                                placement="bottom" 
                                trigger="click"
                                title="错误信息" 
                                content={
                                    <Alert
                                        message=""
                                        description={task.message}
                                        type="error"
                                        showIcon
                                    />
                                } >
                                <Button type="link" danger>错误</Button>
                            </Popover> 
                        }
                    </TaskInformation>

                    <TaskProgress>
                        <Progress
                            size="small"
                            percent={getProgressPercent(task)}
                            status={getProgressStatus(task) as any} 
                            showInfo={false}
                        />
                    </TaskProgress>
                </ExportDeviceMetricsTask>
            })
        }

        {
            tasks.length <= 0 && <NoTaskTips>暂无任务</NoTaskTips>
        }
    </TaskListWrapper>
};

export default TaskList;
