import styled from "styled-components";

export const ContentCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 3px;
    border: 2px solid rgba(95, 112, 150, 0.3);
    background-color: rgba(20, 26, 39, 0.5);
    padding: 10px;
    box-sizing: border-box;
`;

export const Title = styled.div`
    color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    box-sizing: border-box;
    display: flex; 
    flex-direction: row;
    align-items: flex-end;
`;

export const TitleCn = styled.div`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 4px;
`;

export const TitleEn = styled.div`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 2px;
`;

export const TitleSeparator = styled.div`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0px 10px ;
`;

export const TitleBorder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

export const BorderLeftCircle = styled.div`
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0px 5px 0px 0px;
`;

export const BorderLeftBlock = styled.div`
    height: 3px;
    width: 30px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const BorderLine = styled.div`
    flex: 1 1 auto;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const BorderRightBlock = styled.div`
    height: 3px;
    width: 30px;
    border-radius: 1.5px;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const Content = styled.div`
    flex: 1 1 auto;
`;

