import styled from "styled-components";

export const ClockWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const TimeWrapper = styled.div`
    font-size: 24px;
`;

export const DateAndDayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0px 10px;
`;

export const DayWrapper = styled.div`
    font-size: 10px;
    letter-spacing: 5px;
`;

export const DateWrapper = styled.div`
    font-size: 10px;
`;
