import { AxiosResponse } from "axios";
import AxiosService from "./Axios"
import BaseApi from "./BaseApi";

const BASE_URL = "/node";

class NodeApi extends BaseApi {

    constructor() {
        super(BASE_URL);
    }

    public async getAllNodeViews(): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/node-view/all');
    }

    public async getNodeViewsForCreateDevice(model: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/node-view/for-new-device', {
            params: {model: model}
        });
    }

    public async getNodeViewsForOTA(model: string): Promise<AxiosResponse<any>> {
        return AxiosService.get(BASE_URL + '/node-view/for-ota', {
            params: {model: model}
        });
    }
}

export default NodeApi;
