import styled from "styled-components";

export const MainStatusBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
`;

export const LeftWrapper = styled.div`
    display: flex;

`;

export const ViewTitle = styled.div`
    font-size: 16px;
    color: #4F4F4F;
    vertical-align: middle;
`;

export const RightWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NotifyButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 3px;
    margin: 0px 3px;
    font-size: 21px;

    &:hover {
        background-color: #CECECE;
    }
`;

export const NotifyIcon = styled.span`
    font-size: 24px;
    height: 24px;
`;

export const UserProfileButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0px 10px 0px 12px;
    border-radius: 50%;
    padding-bottom: 2px;
    box-sizing: border-box;
    background-color: #101010;
    box-shadow: 0 0 3px #000000;
    color: #FEFEFE;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
`;

