import { CheckCircleFilled, CloseCircleFilled, EditOutlined } from "@ant-design/icons";
import { DeviceInformationWrapper, DeviceLocationMapWrapper, InfoColumn, InfoGroup, InfoGroupContent, InfoGroupSeparator, InfoGroupTitle, InfoItem, InfoLabel, InfoValue, ProductModelPicture, ViewDeviceLink } from "./DeviceTemplateInformation.styled";
import WLS20_Picture from '../../../../../../assets/model-pictures/wls20/wls20.jpg';
import { Amap, CircleMarker } from "@amap/amap-react";
import { Button } from "antd";
import { useEffect, useState } from "react";
import DeviceApi from "../../../../../../api/DeviceApi";
import moment from "moment";
import StringUtils from "../../../../../../util/StringUtils";
import { DeviceStatusUtils } from "../../../../../../components/ui-components/device-status/DeviceStatus";
import DeviceTemplateUtils from "../../../../util/DeviceTemplateUtils";
import { useNavigate } from "react-router-dom";

interface DeviceTemplateInformationProps {
    deviceTemplateView: any
}

export const DeviceTemplateInformation: React.FC<DeviceTemplateInformationProps> = (props)=> {
    // params
    let { deviceTemplateView } = props;

    // api
    let navigate = useNavigate();

    // events
    let onViewDevices = ()=> {
        navigate(`/device-management?deviceTemplateId=${deviceTemplateView.deviceTemplate.id}`)
    };

    // utils
    let formatDateTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    }

    // render
    return (
        <DeviceInformationWrapper>
            <InfoGroup>
                <InfoGroupTitle>基础信息 <Button type='link'><EditOutlined />编辑</Button></InfoGroupTitle>
                <InfoGroupContent>
                    <InfoColumn>
                        <ProductModelPicture style={{
                            backgroundImage: `url(${WLS20_Picture})`
                        }} />
                    </InfoColumn>

                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>模板名称</InfoLabel>
                            <InfoValue>{deviceTemplateView.deviceTemplate.name}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>节点类型</InfoLabel>
                            <InfoValue>{DeviceTemplateUtils.nodeType2Text(deviceTemplateView.deviceTemplate.nodeType)}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>产品型号</InfoLabel>
                            <InfoValue>{deviceTemplateView.deviceTemplate.productModel}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>备注</InfoLabel>
                            <InfoValue>{StringUtils.emptyToSlash(deviceTemplateView.deviceTemplate.remarks)}</InfoValue>
                        </InfoItem>
                    </InfoColumn>

                    <InfoColumn>
                        <InfoItem>
                            <InfoLabel>设备数量 </InfoLabel>
                            <InfoValue>{deviceTemplateView.statistics.deviceCount} <ViewDeviceLink onClick={onViewDevices}><EditOutlined style={{padding: "0px 5px"}}/>管理设备 </ViewDeviceLink></InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>创建时间</InfoLabel>
                            <InfoValue>{formatDateTime(deviceTemplateView.deviceTemplate.createdTime)}</InfoValue>
                        </InfoItem>
                        <InfoItem>
                            <InfoLabel>修改时间</InfoLabel>
                            <InfoValue>{formatDateTime(deviceTemplateView.deviceTemplate.updatedTime)}</InfoValue>
                        </InfoItem>
                    </InfoColumn>
                </InfoGroupContent>
            </InfoGroup>
            
        </DeviceInformationWrapper>
    )
}

export default DeviceTemplateInformation;
